// @flow
import { createSelectorCreator, defaultMemoize } from "reselect";

import * as _ from "lodash";
import { isEmpty, get, isEqual } from "lodash";

import leaguesDraftUtil from "../leaguesDraft";
import type { TPositionsById } from "../../types";
import * as venuesUtils from "../venues";
import * as positionsUtils from "../positions";
import * as roundsSelectorUtils from "../rounds";
import * as roundsHelperUtils from "../../../helpers/rounds";

import * as playersUtilsHelper from "../../../helpers/players";

import  * as draftTeamSelectors from "../teamsDraft";
import * as squadsUtilsSelectors from "../squads";
import type {
	TPlayerWithFixture,
	TPlayersArrayWithFixture,
	TPlayers,
	TCoachPlayerStat,
	TSquadsById,
	TStateWithPlayers,
	TRound, 
	TMatch,
} from "../../types";
import { coachStatsTransformed } from "./coachStatsTransformed";


const createSelector = createSelectorCreator(defaultMemoize, isEqual);

const getPlayerPastYears = (player, past_years) => {
	return Object.keys(past_years)
		.reduce((accumulator: Object, currentValue: string) => {
			return {
				...accumulator,
				[currentValue]: past_years[currentValue][player.id]
			};
		}, {});
};

const combinePlayerYears = (players, past_years) => {
	return _(players)
		.map(player => ({
			...players[player.id],
			past_years: getPlayerPastYears(player, past_years)
		}))
		.keyBy("id")
		.value();
};





export const getPlayers = (state: TStateWithPlayers): Array<Object> => {
	const leagueID = state.leagues.show.league_id;
	const league = get(state.leagues.by_id, leagueID, {});
	const original_positions_enabled = get(league, 'original_positions', 0);
	const players = state.players.ordered_ids.map(item => state.players.by_id[item]);
	return original_positions_enabled === 1 ? players.map(item => ({
		...item,
		positions: item.original_positions
	})): players;
};
	

export const getPlayerById = (state: TStateWithPlayers): Object => state.players.by_id;

export const hasPlayers = (state: TStateWithPlayers): boolean =>
	isEmpty(state.players.ordered_ids) === false;

export const getCoachPlayersById = ({ players }: TStateWithPlayers) =>
	players.coach_by_id;

export const hasCoachPlayers = (state: TStateWithPlayers): boolean =>
	isEmpty(state.players.coach_by_id) === false;

export const getFavourites = ({ players }: TStateWithPlayers) => players.favourites;

export const getFavouritesLastFetched = ({ players }: TStateWithPlayers) =>
	players.favourites_last_fetched;

export const getAllStats = ({ players }: TStateWithPlayers) =>
	players.all_stats;

export const getAllCustomStats = ({ players }: TStateWithPlayers) =>
	players.all_custom_stats;

export const getAllCoachCustomStats = ({ players }: TStateWithPlayers) =>
	players.all_coach_custom_stats;

export const getPlayerVenueStatsClassic = ({ players }: TStateWithPlayers) => 
	players.venue_stats_classic;

export const getPlayerVenueStatsDraft =  ({ players }: TStateWithPlayers) => 
	players.venues_stats_draft;

export const getPlayerOpponentStatsClassic = ({ players }: TStateWithPlayers) => 
	players.player_opposition_classic;

export const getPlayerOpponentStatsDraft = ({ players }: TStateWithPlayers) => 
	players.player_opposition_draft;


const isNotScoredYet = ({
	is_preseason,
	has_score,
	is_match_active,
	is_match_scheduled,
}) => {
	return (
		is_preseason || is_match_scheduled || (is_match_active && !has_score)
	);
};

const setCompetitionFieldToPlayer = (squad_by_id, round, player) => {
	if (_.isEmpty(round)) {
		return player;
	}

	const { matches, bye_squads } = round,
		{ squad_id } = player,
		is_bye = bye_squads.includes(squad_id),
		{ home_squad_id, away_squad_id } =
			roundsHelperUtils.getMatchUsingSquadId(squad_id, matches) || {},
		opponent_squad_id = _.eq(squad_id, home_squad_id)
			? away_squad_id
			: home_squad_id,
		is_at_home = home_squad_id === squad_id;

	return {
		...player,
		squad: squad_by_id[squad_id],
		competition: {
			is_bye,
			is_at_home,
			opponent: squad_by_id[opponent_squad_id],
			symbol: is_bye ? "BYE" : is_at_home ? "V" : "@",
		},
	};
};

const isNotPlay = points =>
	["DNP", "BYE"].includes(String(points).toUpperCase());

const getPointLastRound = ({ is_not_scored_yet, score = "DNP" }) =>
	is_not_scored_yet ? "" : score;

const setStatsPointsLastRound = (round, player) => {
	if (_.isEmpty(_.values(round))) {
		return player;
	}

	const { id, matches, status } = round,
		is_preseason = _.eq(id, 1) && _.eq(status, "scheduled"),
		score = playersUtilsHelper.getPlayerScoreByRound(
			player,
			id,
			id >= 1 ? "DNP" : undefined
		),
		has_score = !_.isUndefined(score),
		player_squad_match = roundsHelperUtils.getMatchUsingSquadId(
			player.squad_id,
			matches
		),
		match_status = _.get(player_squad_match, "status"),
		is_match_active = _.eq(match_status, "playing"),
		is_match_scheduled = _.eq(match_status, "scheduled"),
		is_not_scored_yet = isNotScoredYet({
			is_match_scheduled,
			is_match_active,
			is_preseason,
			has_score,
		}),
		is_bye = _.get(round, "bye_squads", []).includes(player.squad_id),
		points_last_round = is_bye
			? "BYE"
			: getPointLastRound({
				is_not_scored_yet,
				score,
			  });

	return {
		...player,
		is_playing_now: is_match_active && id > 0,
		is_match_scheduled,
		is_dnp: isNotPlay(points_last_round),
		stats: {
			...player.stats,
			points_last_round,
		},
	};
};

const setCustomStatsPointsLastRound = (round, player) => {
	if (_.isEmpty(_.values(round))) {
		return player;
	}

	const { id, matches, status } = round,
		is_preseason = _.eq(id, 1) && _.eq(status, "scheduled"),
		score = playersUtilsHelper.getPlayerCustomScoreByRound(
			player,
			id,
			id >= 1 ? "DNP" : undefined
		),
		has_score = !_.isUndefined(score),
		player_squad_match = roundsHelperUtils.getMatchUsingSquadId(
			player.squad_id,
			matches
		),
		match_status = _.get(player_squad_match, "status"),
		is_match_active = _.eq(match_status, "playing"),
		is_match_scheduled = _.eq(match_status, "scheduled"),
		is_not_scored_yet = isNotScoredYet({
			is_match_scheduled,
			is_match_active,
			is_preseason,
			has_score,
		}),
		is_bye = _.get(round, "bye_squads", []).includes(player.squad_id),
		points_last_round = is_bye
			? "BYE"
			: getPointLastRound({
				is_not_scored_yet,
				score,
			  });

	return {
		...player,
		custom_stats: {
			...player.custom_stats,
			points_last_round,
		},
	};
};

const setStatsCostDividedByAveragePoints = player => ({
	...player,
	stats: {
		...player.stats,
		cost_divided_by_points: playersUtilsHelper.getStatsCostDividedByAveragePoints(
			player
		),
	},
});

const setRoundCostChanges = ({ round_id = 1, filed_name = "cost_round_diff" } = {}, player) => {
	const
		price = _.get(player, `stats.prices[${round_id}]`, player.cost),
		diff = (player.cost - price) || "-",
		new_stat = {};

	new_stat[filed_name] = diff;

	return {
		...player,
		stats: {
			...player.stats,
			...new_stat
		}
	};
};

const setSeasonCostChanges = player =>
	setRoundCostChanges(
		{ 
			filed_name: "cost_season_diff",
			round_id: 1 
		},
		player
	);

const setCostChanges = (player, last_round_id) => {
	const getCostDiff = _.flow([
		setSeasonCostChanges,
		_.partial(setRoundCostChanges, {
			round_id: last_round_id,
			
		}),
	]);

	return getCostDiff(player);
};

// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
const average = (arr: Array<number>) =>
	Math.fround(_.divide(_.sum(arr), _.size(arr))) || 0;

const lastThreePriceChange = player => {
	return _.chain(_.get(player, "stats.prices", {}))
		.values()
		.reverse()
		.take(4)
		.map((price, index, prices) => price - _.result(prices, index + 1, 0))
		.take(3)
		.value();
};

type TCoachStatsParams = {
	coach_by_id: { [id: number | string]: TCoachPlayerStat },
	actual_round_id: number,
	last_round_id: number,
};


const setCoachStats = (
	{ coach_by_id, actual_round_id = 1, last_round_id = 1 }: TCoachStatsParams,
	player: TPlayerWithFixture
) => {
	if (_.isEmpty(coach_by_id)) {
		return player;
	}

	const { id, stats, cost } = player,
		coach_stats = _.get(coach_by_id, id, {}),
		{ proj_scores, proj_prices, break_evens, transfers } = coach_stats;

	const selections_info = stats.selections
		? { ...stats.selections_info, start: 100 - stats.selections_info.bc }
		: null;

	const proj_price_next_round = _.get(proj_prices, actual_round_id + 1, 0),
		proj_price_change_next_round = proj_price_next_round - cost,
		proj_price_change_next_two_rounds =
			_.get(proj_prices, actual_round_id + 2, 0) - proj_price_next_round,
		// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
		in_20_avg_percent = Math.round10(
			(coach_stats.in_20_avg / stats.games_played) * 100,
			0
		),
		// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
		out_20_avg_percent = Math.round10(
			(coach_stats.out_20_avg / stats.games_played) * 100,
			0
		);
	const coach_draft_owned_by = _.get(coach_stats, 'draft_owned_by', 0);
	const transfers_actual_round = _.get(transfers, `[${actual_round_id}]`);
	return {
		...player,
		stats: {
			...stats,
			...coach_stats,
			draft_owned_by: coach_draft_owned_by.toFixed(1),
			selections_info,
			points_2_rounds_prior: playersUtilsHelper.getPlayerScoreByRound(
				player,
				last_round_id - 1,
				"DNP"
			),
			points_3_rounds_prior: playersUtilsHelper.getPlayerScoreByRound(
				player,
				last_round_id - 2,
				"DNP"
			),
			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			last_3_avg_vs_proj: Math.round10(
				stats.last_3_avg - coach_stats.last_3_proj_avg,
				-2
			),
			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			last_3_avg_vs_season: Math.round10(
				stats.last_3_avg - stats.avg_points,
				-2
			),
			break_even_next_round: _.get(break_evens, actual_round_id + 1, 0),
			proj_score_actual_round: _.get(proj_scores, actual_round_id, 0),
			proj_score_next_round: _.get(proj_scores, actual_round_id + 1, 0),
			proj_score_avg_for_3_rds: average(
				_.values(
					_.pick(
						proj_scores,
						_.range(actual_round_id, actual_round_id + 3).map(
							_.toString
						)
					)
				)
			),
			proj_price_change_next_round,
			proj_price_change_next_two_rounds,
			last_3_avg_price_changes: average(lastThreePriceChange(player)),
			in_20_avg_percent: _.isFinite(in_20_avg_percent)
				? `${in_20_avg_percent}%`
				: "-",
			out_20_avg_percent: _.isFinite(out_20_avg_percent)
				? `${out_20_avg_percent}%`
				: "-",
			traded_in_week: transfers_actual_round
				? transfers_actual_round.in
				: "-",
			traded_out_week: transfers_actual_round
				? transfers_actual_round.out
				: "-",
		},
	};
};


const setCoachCustomStats = (
	{ coach_by_id, actual_round_id = 1, last_round_id = 1 }: TCoachStatsParams,
	player: TPlayerWithFixture
) => {
	if (_.isEmpty(coach_by_id)) {
		return player;
	}

	const { id, stats, custom_stats } = player,
		coach_stats = _.get(coach_by_id, id, {}),
		{ proj_scores } = coach_stats;

	return {
		...player,
		custom_stats: {
			...custom_stats,
			...coach_stats,
			points_2_rounds_prior: playersUtilsHelper.getPlayerCustomScoreByRound(
				player,
				last_round_id - 1,
				"DNP"
			),
			points_3_rounds_prior: playersUtilsHelper.getPlayerCustomScoreByRound(
				player,
				last_round_id - 2,
				"DNP"
			),
			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			last_3_avg_vs_proj: Math.round10(
				custom_stats.last_3_avg - coach_stats.last_3_proj_avg,
				-2
			),
			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			last_3_avg_vs_season: Math.round10(
				custom_stats.last_3_avg - stats.avg_points,
				-2
			),
			proj_score_actual_round: _.get(proj_scores, actual_round_id, 0),
			proj_score_next_round: _.get(proj_scores, actual_round_id + 1, 0),
			proj_score_avg_for_3_rds: average(
				_.values(
					_.pick(
						proj_scores,
						_.range(actual_round_id, actual_round_id + 3).map(
							_.toString
						)
					)
				)
			),
		},
	};
};

const setFutureGamesStats = (
	{ rounds, coach_by_id, squad_by_id, venues_by_id, actual_round_id = 1 },
	player
) => {
	if ([rounds, coach_by_id, squad_by_id, venues_by_id].some(_.isEmpty)) {
		return player;
	}

	const { stats } = player,
		coach_stats = _.get(coach_by_id, player.id),
		getValueFrom = _.curry((db, key: number | string, default_val: any) =>
			_.get(db, key, default_val)
		),
		getMatches = _.partial(_.get, _, "matches"),
		isMatchContainSquadId = _.curry(
			(squad_id: number, match: TMatch): boolean =>
				_.at(match, ["home_squad_id", "away_squad_id"]).includes(
					squad_id
				)
		),
		filterByPlayerSquad = _.partial(
			_.filter,
			_,
			isMatchContainSquadId(player.squad_id)
		),
		getOpponentByPlayerSquadId = _.curry(
			(squad_id: number, squad_by_id: TSquadsById, match: TMatch) => {
				const opponent_id = _.chain(match)
					.at(["home_squad_id", "away_squad_id"])
					.without(squad_id)
					.first();

				return _.get(squad_by_id, opponent_id, {});
			}
		),
		getPredictGamesStats = (
			getStats,
			getOpponentFromMatch,
			getVenue,
			match
		) => {
			const { venue_id, round } = match,
				venue = getVenue(venue_id, {}),
				opponent = getOpponentFromMatch(match, {});

			return {
				round,
				venue,
				opponent,
				avg_opposition: getStats(`opponents.${opponent.id}`, "-"),
				avg_at_venue: getStats(`venues.${venue_id}`, "-"),
			};
		};

	const future_games = _.chain(rounds)
		.filter(round => round.id >= actual_round_id)
		.take(3)
		.map(_.flow([getMatches, filterByPlayerSquad]))
		.flatten()
		.map(
			_.partial(
				getPredictGamesStats,
				getValueFrom(coach_stats),
				getOpponentByPlayerSquadId(player.squad_id, squad_by_id),
				getValueFrom(venues_by_id)
			)
		)
		.value();

	const names = [
			"one_round_after_current",
			"two_rounds_after_current",
			"three_rounds_after_current",
		],
		getAvgOpposition = _.partial(_.get, _, "avg_opposition"),
		getAvgVenues = _.partial(_.get, _, "avg_at_venue"),
		avg_oppositions = future_games.map(getAvgOpposition).filter(_.isNumber),
		avg_venues = future_games.map(getAvgVenues).filter(_.isNumber),
		opp_avg_rounds = future_games.reduce((acc, match, index) => {
			acc[`opp_avg_${names[index]}`] = match.avg_opposition;
			acc[`opp_${names[index]}`] = _.get(match, "opponent.short_name");
			return acc;
		}, {}),
		venues_avg_rounds = future_games.reduce((acc, match, index) => {
			acc[`venue_avg_${names[index]}`] = match.avg_at_venue;
			acc[`venue_${names[index]}`] = _.get(match, "venue.short_name");
			return acc;
		}, {});
	
	return {
		...player,
		stats: {
			...stats,
			future_games,
			...opp_avg_rounds,
			...venues_avg_rounds,
			opp_avg_3_rounds: average(avg_oppositions).toFixed(2),
			venue_avg_3_rounds: average(avg_venues).toFixed(2),
		},
	};
};

const setCustomFutureGamesStats = (
	{ rounds, coach_by_id, squad_by_id, venues_by_id, actual_round_id = 1 },
	player
) => {
	if ([rounds, coach_by_id, squad_by_id, venues_by_id].some(_.isEmpty)) {
		return player;
	}

	const { custom_stats } = player,
		coach_stats = _.get(coach_by_id, player.id),
		getValueFrom = _.curry((db, key: number | string, default_val: any) =>
			_.get(db, key, default_val)
		),
		getMatches = _.partial(_.get, _, "matches"),
		isMatchContainSquadId = _.curry(
			(squad_id: number, match: TMatch): boolean =>
				_.at(match, ["home_squad_id", "away_squad_id"]).includes(
					squad_id
				)
		),
		filterByPlayerSquad = _.partial(
			_.filter,
			_,
			isMatchContainSquadId(player.squad_id)
		),
		getOpponentByPlayerSquadId = _.curry(
			(squad_id: number, squad_by_id: TSquadsById, match: TMatch) => {
				const opponent_id = _.chain(match)
					.at(["home_squad_id", "away_squad_id"])
					.without(squad_id)
					.first();

				return _.get(squad_by_id, opponent_id, {});
			}
		),
		getPredictGamesStats = (
			getStats,
			getOpponentFromMatch,
			getVenue,
			match
		) => {
			const { venue_id, round } = match,
				venue = getVenue(venue_id, {}),
				opponent = getOpponentFromMatch(match, {});

			return {
				round,
				venue,
				opponent,
				avg_opposition: getStats(`opponents.${opponent.id}`, "-"),
				avg_at_venue: getStats(`venues.${venue_id}`, "-"),
			};
		};

	const future_games = _.chain(rounds)
		.filter(round => round.id >= actual_round_id)
		.take(3)
		.map(_.flow([getMatches, filterByPlayerSquad]))
		.flatten()
		.map(
			_.partial(
				getPredictGamesStats,
				getValueFrom(coach_stats),
				getOpponentByPlayerSquadId(player.squad_id, squad_by_id),
				getValueFrom(venues_by_id)
			)
		)
		.value();

	const names = [
			"one_round_after_current",
			"two_rounds_after_current",
			"three_rounds_after_current",
		],
		getAvgOpposition = _.partial(_.get, _, "avg_opposition"),
		getAvgVenues = _.partial(_.get, _, "avg_at_venue"),
		avg_oppositions = future_games.map(getAvgOpposition).filter(_.isNumber),
		avg_venues = future_games.map(getAvgVenues).filter(_.isNumber),
		opp_avg_rounds = future_games.reduce((acc, match, index) => {
			acc[`opp_avg_${names[index]}`] = match.avg_opposition;
			acc[`opp_${names[index]}`] = _.get(match, "opponent.short_name");
			return acc;
		}, {}),
		venues_avg_rounds = future_games.reduce((acc, match, index) => {
			acc[`venue_avg_${names[index]}`] = match.avg_at_venue;
			acc[`venue_${names[index]}`] = _.get(match, "venue.short_name");
			return acc;
		}, {});

	return {
		...player,
		custom_stats: {
			...custom_stats,
			future_games,
			...opp_avg_rounds,
			...venues_avg_rounds,
			opp_avg_3_rounds: average(avg_oppositions).toFixed(2),
			venue_avg_3_rounds: average(avg_venues).toFixed(2),
		},
	};
};

const setByeRound = ({ bye_rounds }, player: TPlayerWithFixture) => {
	const bye_round = _.find(bye_rounds, ({ bye_squads }) =>
		bye_squads.includes(player.squad_id)
	);

	return {
		...player,
		stats: {
			...player.stats,
			bye_round_id: _.get(bye_round, "id"),
		},
	};
};

export const getPlayersArrayWithFixture = createSelector(
	getPlayers,
	squadsUtilsSelectors.getSquadsById,
	roundsSelectorUtils.getActualRound,
	(state, props, round_id) => {
		if (!round_id) {
			return roundsSelectorUtils.getLastRound(state);
		}
		return roundsSelectorUtils.getRoundById(state, round_id);
	},
	getCoachPlayersById,
	roundsSelectorUtils.getRoundsWithMatches,
	venuesUtils.getVenuesById,
	roundsSelectorUtils.getByeRounds,
	getAllCustomStats,
	roundsSelectorUtils.getActualRoundForCompetitionField,
 	getAllCoachCustomStats,
	(
		players: TPlayers,
		squad_by_id: TSquadsById,
		actual_round: TRound,
		last_round: TRound,
		coach_by_id: { [id: number | string]: TCoachPlayerStat },
		rounds: Array<TRound>,
		venues_by_id: Object,
		bye_rounds: Array<TRound>,
		all_custom_stats: Object,
		competition_actual_round: TRound,
		all_coach_custom_stats: Object
	): TPlayers | TPlayersArrayWithFixture => {
		const playersWithCustom = players.map(player => ({
			...player,
			custom_stats: _.get(all_custom_stats, `[${player.id}]`, {}),
		}));

		if (_.some([squad_by_id, actual_round, last_round], _.isEmpty)) {
			return playersWithCustom;
		}

		const setCompetitionField = _.partial(
			setCompetitionFieldToPlayer,
			squad_by_id,
			competition_actual_round,
			_
		);

		const setPointsLastRound = _.partial(
			setStatsPointsLastRound,
			last_round,
			_
		);

		const setCustomPointsLastRound = _.partial(
			setCustomStatsPointsLastRound,
			last_round,
			_
		);

		const setPlayerCoachStats = _.partial(
			setCoachStats,
			{
				coach_by_id,
				actual_round_id: actual_round.id,
				last_round_id: last_round.id,
			},
			_
		);

		const setPlayerCoachCustomStats = _.partial(
			setCoachCustomStats,
			{
				coach_by_id: all_coach_custom_stats,
				actual_round_id: actual_round.id,
				last_round_id: last_round.id,
			},
			_
		);

		const setPlayerFutureGamesStats = _.partial(
			setFutureGamesStats,
			{
				rounds,
				coach_by_id,
				squad_by_id,
				venues_by_id,
				actual_round_id: actual_round.id,
			},
			_
		);

		const setPlayerCustomFutureGamesStats = _.partial(
			setCustomFutureGamesStats,
			{
				rounds,
				coach_by_id: all_coach_custom_stats,
				squad_by_id,
				venues_by_id,
				actual_round_id: actual_round.id,
			},
			_
		);

		const setPlayerFutureByRound = _.partial(
			setByeRound,
			{ bye_rounds: bye_rounds },
			_
		);

		// Pass through last full round to cost changes function
		//    - when the current round is active, last_round === actual_round,
		//    - that means, you can't just  use last_round
		// So always should be actual_round - 1
		let cost_round = Math.max(1, actual_round.id - 1);
		const setPlayerCostChanges = _.partial(setCostChanges, _, cost_round);

		return playersWithCustom.map(
			_.flow([
				setCompetitionField,
				setStatsCostDividedByAveragePoints,
				setPlayerCostChanges,
				setPointsLastRound,
				setCustomPointsLastRound,
				setPlayerCoachStats,
				setPlayerCoachCustomStats,
				setPlayerFutureGamesStats,
				setPlayerFutureByRound,
				setPlayerCustomFutureGamesStats,
			])
		);
	}
);

export const getExtendedPlayersArray = createSelector(
	getPlayersArrayWithFixture,
	positionsUtils.getPositionsById,
	getFavourites,
	(
		players: TPlayersArrayWithFixture,
		positions_by_id: TPositionsById,
		favourites: Array<number>
	) => {
		if(players){
			return players.map(player => ({
				...player,
				is_favourite: (favourites || []).includes(player.id),
				positions_name: player.positions.map(
					id => positions_by_id[id].short_name
				),
				positions_full_name: player.positions.map(
					id => positions_by_id[id].name
				),
			}));
		}
		return [];
	}
		
);

export const getExtendedPlayersArrayWithOwners = createSelector(
	getExtendedPlayersArray,
	leaguesDraftUtil.getLeague,
	leaguesDraftUtil.getTeamsByIdWithPureLineups,
	leaguesDraftUtil.getWaiversList,
	leaguesDraftUtil.getWaiversFree,
	(
		players: TPlayersArrayWithFixture,
		league: Object,
		teams_by_id: Object,
		rfa: Object,
		fa: Array<number>
	) => {
		const is_rfa_enabled = league.trade_waivers;
		const user_team_id = league.team_id;
		const getOwner = player => {
			return _.find(teams_by_id, team => {
				const { lineup_ids } = team;
				return lineup_ids.includes(player.id);
			});
		};

		return players.map(player => {
			const { id } = player;
			if (is_rfa_enabled && rfa[id]) {
				return {
					...player,
					waiver: "Restricted Free Agent",
					waiver_mobile: "RFA",
					expiry: rfa[id].time,
					owner: false,
				};
			}
			else if (fa.includes(id)) {
				return {
					...player,
					waiver: "Free Agent",
					waiver_mobile: "FA",
					owner: false,
				};
			}

			const owner = getOwner(player);

			return {
				...player,
				owner,
				is_user_team: owner && owner.id === user_team_id,
			};
		});
	}
);

export const getPreDraftPlayers = createSelector(
	draftTeamSelectors.isPDLReceived,
	draftTeamSelectors.getPreDraftList,
	getExtendedPlayersArray,
	(
		has_pdl: boolean,
		pre_draft_list: Array<number>,
		players: TPlayersArrayWithFixture
	) =>
		has_pdl
			? players.map(player => ({
				...player,
				draft_order: pre_draft_list.indexOf(player.id) + 1,
			  }))
			: []
);

export const getExtendedPlayersById = createSelector(
	getExtendedPlayersArray,
	players => {
		if(players){
			return _.keyBy(players, "id");
		}
		return {};
	} 
);

export const getExtendedPlayersWithOwnersById = createSelector(
	getExtendedPlayersArrayWithOwners,
	players => _.keyBy(players, "id")
);

export const getTeamBreakdownPlayersById = createSelector(
	getPlayers,
	positionsUtils.getPositionsById,
	(players: TPlayers, positions_by_id: TPositionsById) => {
		return players.reduce((acc, player) => {
			acc[player.id] = {
				...player,
				positions_name: player.positions.map(
					id => positions_by_id[id].short_name
				),
			};
			return acc;
		}, {});
	}
);

export const getExtendedPlayersWithPastYearsClassic = createSelector(
	getExtendedPlayersById,
	state => state.players.past_years,
	combinePlayerYears
);

export const getExtendedPlayersWithPastYearsDraft = createSelector(
	getExtendedPlayersWithOwnersById,
	state => state.players.past_years,
	combinePlayerYears
);



const myPlayersSelector = createSelector(
	state => _.get(state, "teamsClassic.show_my.team.lineup", null),
	state => state.positions.ordered_ids,
	(lineup, positions) => {
		if (_.isEmpty(lineup)) {
			return null;
		}

		const getFlattenedPlayers = (set, includeUtility = false) => {
			const players = _(positions)
			  .map(pos => _.get(set, pos, []))
			  .flatten()
			  .value();
		  
			if (includeUtility && _.get(set, 'utility.id')) {
			  return [...players, set.utility.id].filter(id => id !== 0);
			}
		  
			return players.filter(id => id !== 0);
		  };

		const starting_team = getFlattenedPlayers(lineup);
		const bench = getFlattenedPlayers(lineup.bench, true);
		
		return [...starting_team, ...bench];
	}
);

const pastYearPlayersSelector = state => state.players.past_years;

export const playersForClassicPlayers = createSelector(
	getExtendedPlayersArray, // playersSelector
	myPlayersSelector,
	pastYearPlayersSelector,
	coachStatsTransformed,
	(
		players,
		my_players,
		past_years,
		coach_stats_transformed,
	) => {
		return players.map(player => {

			const past_years_player = Object.keys(past_years)
				.reduce((accumulator: Object, currentValue: string) => {
					return {
						...accumulator,
						[currentValue]: past_years[currentValue][player.id]
					};
				}, {});

			return {
				...player,
				is_owned_by_user: my_players && my_players.includes(player.id),
				not_owned_by_user: my_players && !my_players.includes(player.id),
				past_years: past_years_player,
				stats: {
					...player.stats,
					...coach_stats_transformed[player.id],
				},
			};
		});
	}
);