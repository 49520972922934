import styled from "styled-components";
import above, { below } from "../../../assets/css/media";
import {
	Tabs,
	TabItem,
    
} from "../../";
import Table, { Td, TBody } from "../../TableX";
import ThStatUnstyled from "../../ThStat";
import colors from "../../../assets/css/colors";


export const ROUND_WIDTH = 3;
export const TEAM_WIDTH = 16;
export const VENUE_WIDTH = 6;
export const OPPONENT_TAB_TEAM = 27;
export const VENUE_TAB_WIDTH = 27;

export const StyledTabs = styled(Tabs)`
	height: 53px;
	overflow-x: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	
	&:-webkit-scrollbar {
		display: none;
	}
`;

export const StyledTabItem = styled(TabItem)`
	min-width: 101px;
	height: 100%;
	border-top: 0;
	display: inline-block;
	padding: 20px 0;

	${above.tablet`
		&.playerOverview{
			width: 124px;
		}

		&.fixture{
			width: 106px;
		}
	`}

	${below.tablet`
		width: fit-content;
	`}
`;

export const TdStat = styled(Td)`
	height: 30px;
	width: ${props => props.statColWidth ? props.statColWidth : "auto"};
	font-weight: 200;
	color: #1D4073;
	box-sizing: border-box;
	border-bottom: none;
	tr:nth-child(odd) > & {
		background: #fff;
	}
`;
export const MatchTd = styled(TdStat)`
	height: ${props => props.isEmpty ? "auto" : "60px"};
`;
export const TdRound= styled(TdStat)`
	width: ${ROUND_WIDTH}%;
`;
export const TdTeam = styled(TdStat)`
	width: ${TEAM_WIDTH}%;
	text-align: left;
	font-weight: bold;
`;

export const TdOpponentTeam = styled(TdStat)`
	width: ${OPPONENT_TAB_TEAM}%;
	text-align: left;
	font-weight: bold;
`;

export const TdVenueName = styled(TdStat)`
	width: ${VENUE_TAB_WIDTH}%;
	font-weight: bold;
`;

export const TdLogo = styled(TdStat)`
	width: 40px;
	text-align: center;
`;
export const TdBye = styled(TdLogo)``;
export const TdVenue = styled(TdStat)`
	width: ${VENUE_WIDTH}%;
`;

export const StyledTBody = styled(TBody)`
	${({ showRelative }) => showRelative && `
		position: relative;
	`}
`;

export const ThStat = styled(ThStatUnstyled)`
	width: ${props => props.statColWidth ? props.statColWidth : "auto"};
	color: ${props => props.isUnplayed ? colors.secondary.accentGrey : "auto"};
	box-sizing: border-box;
	background: unset;

	white-space: nowrap;
	text-overflow: ellipsis;

`;
export const ThTeam = styled(ThStat)`
	width: ${TEAM_WIDTH}%;
	text-align: left;
`;

export const ThOpponentTeam = styled(ThStat)`
	width: ${OPPONENT_TAB_TEAM}%;
	text-align: left;
	background: white;
	z-index: 90;
`;

export const ThVenueName = styled(ThStat)`
	width: ${VENUE_TAB_WIDTH}%;
	background: white;
	z-index: 90;
`;

export const ThLogo = styled(ThStat)`
	width: 40px;
	text-align: center;
	font-size: 0;
	${below.tablet`
		font-size: 12px;
		width: 5%;
	`}
`;
export const ThRound = styled(ThStat)`
	width: ${ROUND_WIDTH}%;
	text-align: center!important;
	overflow: visible;
`;
export const ThVenue = styled(ThStat)`
	width: ${VENUE_WIDTH}%;
`;
export const TeamContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
`;
export const Logo = styled.img`
	height: 20px;
	width: 20px;
`;
export const TeamName = styled.div`
	color: #1D4073;
`;
export const MatchStatContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-around;
`;
export const MatchScore = styled.div`
	text-align: center;
	padding: 10px 5px 0;
	font-family: SourceSansPro;
`;
export const MatchValue = styled.div`
	font-family: SourceSansPro;
`;

export const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;
export const Tr = styled.tr`
	height: 30px;
	position: relative;
	${below.phone`
		.absolute-left{
			position: sticky;
			background-color: white;
			left: 0px;
			z-index: 90;
		}
		.absolute-left-second{
			position: sticky;
			background-color: white;
			left: 30px;
			box-shadow: rgb(202 210 216) 15px 0px 15px -5px;
			z-index: 90;
		}
		.absolute-left-second-main{
			position: sticky;
			background-color: white;
			left: 40px;
			box-shadow: rgb(202 210 216) 15px 0px 15px -5px;
			z-index: 90;
		}
		.absolute-group-right{
			position: sticky;
			background-color: white;
			right: 0px;
			box-shadow: rgb(202 210 216) -10px 0px 20px 0px;
			z-index: 90;
		}
		.absolute-left-venue{
			position: sticky;
			background-color: white;
			left: 0px;
			box-shadow: rgb(202 210 216) 15px 0px 15px -5px;
			z-index: 90;
		}
	`}
`;
export const TrTotals = styled(Tr)`
	border-top: 4px solid ${colors.secondary.accentGrey};

	${Td} {
		font-weight: bold;
	}
`;

export const StatsTable = styled(Table)`
	table-layout: fixed;

	border-collapse: separate;
`;

export const PaywallOverlay = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #B89344;
	width: calc(100% - (${({ widthDifference }) => widthDifference}));
	height: 100%;
	z-index: 90;
	right: 0px;
	cursor: pointer;
	svg{
		path{
			fill: #B89344;
		}
	}
	${below.phone`
		width: calc(100% - 132px);
	`}
`;


export const StatsWrapper = styled.div`
	${below.tablet`
		width: 100%;
		overflow: auto hidden;/
		position: relative;
		white-space: nowrap;
		table {
			display: block;
			
			th {
				 position: relative;
				 top: 0;
			}
			th, td {
				display: table-cell;
			}
		}
	`};
	
`;