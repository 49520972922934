// @flow
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _, { isEmpty } from "lodash";

import * as actions from "../modules/actions";
import * as selectors from "../modules/selectors";
import type { TUiGameSelectionFavourite } from "../modules/types/store/uiGameSelectionReducer";

import GameSelectionBgImg from "../assets/images/game-selection-bg.webp";
import LogoAFLFantasyImage from "../assets/images/logos/svgs/afl-ver-cen-black.svg";
// import UFLogo from "../assets/images/uf-logo.svg";
import colors from "../assets/css/colors";
import { above, below } from "../assets/css/media";

import {
	Ad,
	AdsContainer,
	ButtonPrimaryLink,
	// Exist,
	Footer,
	PageContentWrapper,
	Preloader,
	SearchField,
	SearchInputWrapper,
	SearchSubmitButton,
	TeamUserAvatar,
	Exist
} from "../components";
import JsonFetcher from "../components/utils/JsonFetcher";

import CautionIcon from "../components/Icons/Caution";
import OverviewIcon from "../components/Icons/Overview";
import SearchIcon from "../components/Icons/Search";
import { SEASON_YEAR } from "../modules/constants";
import type { TPLeague, TRound, TClassicTeam, TUser } from "../modules/types";
// import { isAllTrue } from "../helpers";
import { isMobile } from "../utils";
import BoxDottedBorder from "./gameSelection/boxDottedBorder";
import BoxLeague, {
	BoxLeagueNameText,
	BoxLeagueNameSubText,
	BoxInner
} from "./gameSelection/boxLeague";
import { FavouriteStarIcon, FavouriteButton } from "./gameSelection/favouriteButton";
import { SelectLeague } from "./gameSelection/league";

const StyledPageWrapper = styled(PageContentWrapper)`
	${below.phone`
		padding: 0 0px;

	`};
`;
const BoxBorder = styled.div`
	box-sizing: border-box;
	width: 100%;
	background: #FFFFFF;
	border: 1px solid #CAD2D8;
	border-radius: 5px;
	padding: 20px;
	margin: 10px 0;
`;
const PageOutterWrapper = styled.div`
	position: relative;
	overflow: hidden;
	min-height: 620px;
`;

const BannerWrapper = styled.div`
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	background-image: url(${GameSelectionBgImg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 10% top;
	min-height: 620px;
`;

const AboveTablet = styled.div`
	${below.tablet`
		display: none;
	`};
`;

const BelowTablet = styled.div`
	${above.tablet`
		display: none;
	`};
`;

const TwoColumnThirdLayout = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	> * {
		margin: 0 auto;
		width: 490px;
 	}	

	${below.tablet`
		flex-direction: column;
		> * {
			width: 100%;
		}
	`};
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
    align-items: center;
`;

const GameLogoWrap = styled.div`
	width: 200px;
	${below.tablet`
		margin: 0 auto;
	`};
`;

const LogoButton = styled.button`
	width: 200px;
	${below.tablet`
		margin: 0 auto;
	`};
	border: none;
	background: transparent;
`;

const GameLogo = styled.img`
	position: relative;
	display: block;
	max-width: 100%;
	margin: 0 auto 20px;
	transition: transform 0.5s, opacity 0.5s, margin 0.5s;
	z-index: 1;

	.slick-slide:not(.slick-active) & {
		margin: 0 0 20px ${({ first }) => first ? "auto" : "0px"};
		transform: translateX(${({ first }) => first ? "42%" : "-42%"}) scale(0.8);
		opacity: 0.3;
		z-index: 0;
	}
	${({isFaded}) => isFaded && `
		opacity: 0.6;
	`}
`;

const GameInfoHeaderText = styled.h3`
	font-size: 32px;
	font-weight: 700;
	line-height: 1.1;
	text-align: center;
	color: ${colors.primary.primary};
	margin: 10px 0;
`;

const GameInfoText = styled.p`
	max-width: 450px;
	font-size: 16px;
	line-height: 1.25;
	color: ${colors.primary.primary};
	text-align: center;
	margin: 10px auto;
	font-family: SourceSansPro;
	min-height: 140px;
	${below.tablet`
		min-height: inherit;
	`};
`;

const GameInfo = styled.div`
	margin: 10px 0 20px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    width: fit-content;
	padding: 0 20px;
	${below.tablet`
		padding: 20px;
	`};
`;

const BoxIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	font-size: 40px;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

const BoxActionText = styled.div`
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	line-height: 1.2;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FlexRowFull = styled.div`
	display: flex;
	width: 100%;
`;

const GameWrapperHeading = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const ButtonGroup = styled.div`
	display: flex;

	> * {
		flex: 0 1 50%;
		margin-right: 10px;
	}
	> *:last-child {
		margin-right: 0px;
	}
`;

const TeamUserAvatarWrapper = styled.div`
	width: 60px;
	height: 60px;
	margin: -50px auto 10px;
`;



type Props = {
	setGameSelectionFavourite: typeof actions.uiGameSelection.setFavourite,
	clearGameSelectionFavourite: typeof actions.uiGameSelection.clearFavourite,
	fetchMyClassicLeagues: typeof actions.showMyClassicLeagues,
	fetchMyClassicTeam: typeof actions.fetchMyClassicTeam,
	fetchMyDraftLeagues: typeof actions.leagueDraft.showMyDraftLeagues,
	my_draft_leagues: Array<Object>,
	has_draft_leagues: boolean,
	my_classic_leagues: Array<Object>,
	fetchUser: typeof actions.fetchUser,
	has_classic_leagues: boolean,
	my_classic_team: TClassicTeam,
	is_classic_team_complete: boolean,
	fetchClassicRegenerateList: typeof actions.regenerateListClassicLeagues,
	fetchRegenerateClassicLeague: typeof actions.regenerateShowClassicLeague,
	// fetchDraftRegenerateList: typeof actions.leagueDraft.regenerateListDraftLeagues,
	fetchRegenerateDraftLeague: typeof actions.leagueDraft.regenerateShowDraftLeague,
	regenerateShowDraftLeagueUF: typeof actions.leagueDraft.regenerateShowDraftLeagueUF,
	clearLeagueCreateId: typeof actions.leagueDraft.clearUFDraftLeagueCreate,
	user: TUser,
	classic_regenerate_list: Object,
	draft_regenerate_list: Object,
	classic_regen_leagues: TPLeague[],
	draft_regen_leagues: TPLeague[],
	draft_regen_leagues_uf: TPLeague[],
	uf_data: Object,
	userIsPending: boolean,
	history: {
		push: Function
	},
	game_selection_favourite: TUiGameSelectionFavourite,
	location: Object,
	leaveClassicLeague: Function,
	leaveDraftLeague: Function,
	actual_round: TRound,
}

type State = {
	search_classic_join: string,
	search_draft_join: string,
	selected_regenerated_league_id: number,
	active_mobile_tab: string
}

class GameSelection extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"favourite",
			"clearFavourite",
			"handleChange",
			"submitClassicJoin",
			"renderClassic",
			"toggleMobileTab"
		]);
	}

	state = {
		search_classic_join: "",
		search_draft_join: "",
		selected_regenerated_league_id: 0,
		active_mobile_tab: "classic"
	};

	componentDidMount() {
		const {
			fetchMyClassicTeam,
			fetchUser,
			fetchClassicRegenerateList,
			// fetchDraftRegenerateList,
			clearLeagueCreateId
		} = this.props;
		clearLeagueCreateId();
		fetchUser();
		
		fetchMyClassicTeam();
		
		this.fetchLeagues();
		fetchClassicRegenerateList();
		// fetchDraftRegenerateList();
	}

	componentDidUpdate(prev_props: Props) {

		if(!isEmpty(this.props.classic_regenerate_list) 
			&& prev_props.classic_regenerate_list.length === 0 
			&& this.props.classic_regenerate_list.length > 0) {
			this.props.classic_regenerate_list.forEach(v => {
				this.props.fetchRegenerateClassicLeague({ id: v.id });
			});
		}
		if(	!isEmpty(this.props.draft_regenerate_list) &&
			prev_props.draft_regenerate_list.length === 0 
			&& this.props.draft_regenerate_list.length > 0) {
			this.props.draft_regenerate_list.forEach(v => v.is_uf ? 
				this.props.regenerateShowDraftLeagueUF({ 
					id: v.id, 
					game:"uf"
				}):this.props.fetchRegenerateDraftLeague({ 
					id: v.id, 
					game: "fantasy"
				})
			);
		}
	}

	slider = React.createRef();

	

	favourite({ game, type, id }) {
		const { setGameSelectionFavourite, user } = this.props;
		setGameSelectionFavourite({ user_id: user.id, game, type, id });
	}

	clearFavourite() {
		const { clearGameSelectionFavourite, user } = this.props;
		clearGameSelectionFavourite({ user_id: user.id });
	}

	handleChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	submitClassicJoin(e) {
		e.preventDefault();
		this.props.history.push(`/classic/leagues/join/${this.state.search_classic_join}`);
	}


	toggleMobileTab() {
		const nextValue = this.state.active_mobile_tab === "classic" ? "draft" : "classic";
		this.setState({
			active_mobile_tab: nextValue
		});
	}

	setMobileTab(val) {
		this.setState({
			active_mobile_tab: val
		});
	}

	get isDraft() {
		return window.location.pathname.substr(0, 6) === "/draft";
	}


	get isClassic() {
		return window.location.pathname.substr(0, 8) === "/classic";
	}

	fetchLeagues(){
		const {
			fetchMyClassicLeagues,
			fetchMyDraftLeagues
		} = this.props;
		fetchMyDraftLeagues();
		fetchMyClassicLeagues();
		
	}
	renderClassicTeam() {
		const {
			my_classic_team,
			is_classic_team_complete,
			game_selection_favourite,
			user
		} = this.props;

		if (my_classic_team && my_classic_team.value === 0) {
			return (
				<BoxDottedBorder>
					<BoxInner>
						<BoxIconWrapper>
							<CautionIcon />
						</BoxIconWrapper>
						<BoxActionText>
							You are yet to select your team
						</BoxActionText>
						<ButtonPrimaryLink to={"/classic/team"}>
							Select your team
						</ButtonPrimaryLink>
					</BoxInner>
				</BoxDottedBorder>
			);
		}

		if (!is_classic_team_complete) {
			return (
				<BoxDottedBorder>
					<BoxInner>
						<BoxIconWrapper>
							<CautionIcon />
						</BoxIconWrapper>
						<BoxActionText>
							Your team is currently incomplete
						</BoxActionText>
						<ButtonPrimaryLink to={"/classic/team"}>
							Complete your team
						</ButtonPrimaryLink>
					</BoxInner>
				</BoxDottedBorder>
			);
		}

		const favourite_object = {
			game: "classic", type: "team", id: my_classic_team.id
		};
		const is_favourite = _.isEqual(favourite_object, game_selection_favourite);
		const favouriteFunction = is_favourite ?
			this.clearFavourite : () => this.favourite(favourite_object);

		return (
			<BoxLeague>
				<FavouriteButton
					onClick={favouriteFunction}
					active={is_favourite}
				>
					<FavouriteStarIcon />
				</FavouriteButton>
				<BoxInner>
					<TeamUserAvatarWrapper>
						<TeamUserAvatar
							{...my_classic_team}
							avatar_version={user.avatar_version}
							firstname={user.firstname}
							lastname={user.lastname}
							is_classic
						/>
					</TeamUserAvatarWrapper>
					<BoxLeagueNameText>{my_classic_team.name}</BoxLeagueNameText>
					<BoxLeagueNameSubText>
						{`${user.firstname} ${user.lastname}`}
					</BoxLeagueNameSubText>
					<ButtonPrimaryLink to={"/classic/team"}>
						View your team
					</ButtonPrimaryLink>
				</BoxInner>
			</BoxLeague>
		);
	}

	renderClassicLeagues() {
		const { my_classic_leagues } = this.props;
		
		return my_classic_leagues.map(league => league && this.renderLeagueContent(league, false));
	}

	renderClassicJoinCreate() {
		const { has_classic_leagues } = this.props;

		return (
			<BoxBorder>
				<BoxInner>
					<BoxIconWrapper>
						{has_classic_leagues ? <OverviewIcon /> : <CautionIcon />}
					</BoxIconWrapper>
					<BoxActionText>
						{has_classic_leagues ?
							"Join or create another classic league" :
							"You are not in any league"
						}
					</BoxActionText>
					<ButtonGroup>
						<ButtonPrimaryLink to={"/classic/leagues/join"}>
							Join a classic league
						</ButtonPrimaryLink>
						<ButtonPrimaryLink to={"/classic/leagues/create"}>
							Create a classic league
						</ButtonPrimaryLink>
					</ButtonGroup>
				</BoxInner>
			</BoxBorder>
		);
	}

	renderClassicJoinWithPin() {
		return (
			<BoxBorder>
				<BoxInner>
					<BoxActionText>
						Already have a classic league PIN?
					</BoxActionText>
					<form onSubmit={this.submitClassicJoin}>
						<SearchInputWrapper>
							<SearchField
								id="search_classic_join"
								name="search_classic_join"
								onChange={this.handleChange}
								placeholder="Enter a private PIN"
							/>
							<SearchSubmitButton type="submit">
								<SearchIcon />
							</SearchSubmitButton>
						</SearchInputWrapper>
					</form>
				</BoxInner>
			</BoxBorder>
		);
	}

	renderClassicRegenLeagues() {
		const { classic_regen_leagues } = this.props;

		return classic_regen_leagues.map(
			league => 
				this.renderRegenerateLeague(
					league,
					true,
					false
				));
	}

	renderLeagueContent(league: TPLeague, is_draft: boolean) {
		const {
			game_selection_favourite,
			user,
			actual_round,
		} = this.props;

		return <SelectLeague
			key={league.id}
			league={league}
			is_draft={is_draft}
			is_regenerate={false}
			game_selection_favourite={game_selection_favourite}
			clearFavourite={this.clearFavourite}
			setFavourite={this.favourite}
			user={user}
			actual_round={actual_round}
			hide_regenerate_button={false}
		/>;
	}

	renderRegenerateLeague(
		league: TPLeague,
		is_classic: boolean,
		hide_regenerate_button: boolean
	) {
		const {
			game_selection_favourite,
			user,
			actual_round,
		} = this.props;
		return <SelectLeague
			key={league.id}
			league={league}
			is_draft={!is_classic}
			is_regenerate={true}
			game_selection_favourite={game_selection_favourite}
			clearFavourite={this.clearFavourite}
			setFavourite={this.favourite}
			user={user}
			actual_round={actual_round}
			hide_regenerate_button={hide_regenerate_button}
		/>;
	}

	renderMobileTabsSection() {
		const classicFaded = this.state.active_mobile_tab === "draft";
		return(
			<FlexCol>
				<FlexRowFull>
					<LogoButton>
						<GameLogo
							alt="AFL Fantasy Image"
							first
							src={LogoAFLFantasyImage}
							isFaded={classicFaded}
						/>
					</LogoButton>
				</FlexRowFull>
			</FlexCol>
		);
	}

	renderClassic() {
		return (
			<React.Fragment>
				{this.renderClassicTeam()}
				{this.renderClassicLeagues()}
				{this.renderClassicRegenLeagues()}
			</React.Fragment>
		);
	}

	render() {
		const {user, userIsPending} = this.props;
		const is_mobile = isMobile();

		if(!user || userIsPending){
			return <Preloader />;
		}
		return (
			<React.Fragment>
				<JsonFetcher fetch={["players", "rounds", "squads", "venues"]} />
				<AdsContainer>
					<Ad id="gameselection" />
				</AdsContainer>

				<PageOutterWrapper>
					<BannerWrapper />
					<StyledPageWrapper>
						<AboveTablet>
							<Exist when={!is_mobile}>
								<GameWrapperHeading>
									<GameLogoWrap>
										<GameLogo
											alt="AFL Fantasy Image"
											first
											src={LogoAFLFantasyImage}
											onClick={e => {
												e.preventDefault();
												if (
													this.slider &&
									this.slider.current &&
									typeof this.slider.current.slickPrev === "function"
												) {
													this.slider.current.slickPrev();
												}
											}}
										/>
									</GameLogoWrap>
									<GameInfo>
										<GameInfoHeaderText>
							Now open for the {SEASON_YEAR} season
										</GameInfoHeaderText>
										<GameInfoText>Play our famous salary cap game
						to win amazing prizes!<br />
						You must select 30 players that fit within the budget
						provided to build your squad.<br />
						You have the opportunity to make changes to your team
						each week and can join leagues to play against your mates or other AFL
						Fantasy enthusiasts.
										</GameInfoText>
									</GameInfo>
								</GameWrapperHeading>
								
							</Exist>
							<TwoColumnThirdLayout>
								<Column>{this.renderClassic()}</Column>
								<Column>
									{this.renderClassicJoinCreate()}
									{this.renderClassicJoinWithPin()}
								</Column>
							</TwoColumnThirdLayout>
						</AboveTablet>

						<BelowTablet>
							{this.renderMobileTabsSection()}

							{this.renderClassic()}
							{this.renderClassicJoinCreate()}
							{this.renderClassicJoinWithPin()}
	
						
							
								
						</BelowTablet>
					</StyledPageWrapper>
					<Footer />
				</PageOutterWrapper>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const my_classic_leagues = selectors.leaguesClassic.show_my.getLeagues(state, props);
	const my_draft_leagues = selectors.leagues.show_my.getLeagues(state);
	return {
		user: selectors.getUser(state),
		// Classic
		my_classic_leagues,
		has_classic_leagues: !_.isEmpty(my_classic_leagues),
		my_classic_team: selectors.getMyClassicTeam(state),
		is_classic_team_complete: selectors.isMyClassicTeamComplete(state, props),
		classic_regenerate_list: state.leaguesClassic.regenerate_list.result || [],
		classic_regen_leagues: 
			state.leaguesClassic.regenerate_list.result
				.filter(l => l.id in state.leaguesClassic.regenerate_show.by_id)
				.map(l => state.leaguesClassic.regenerate_show.by_id[l.id]),
		// Draft
		my_draft_leagues,
		has_draft_leagues: !_.isEmpty(my_draft_leagues),
		game_selection_favourite: selectors.uiGameSelection.getFavourite(state),
		actual_round: selectors.rounds.getActualRound(state),
		draft_regenerate_list: state.leagues.regenerate_list.result || [],
		draft_regen_leagues: state.leagues.regenerate_list.result
			.filter(l => l.id in state.leagues.regenerate_show.by_id)
			.map(l => state.leagues.regenerate_show.by_id[l.id]) || [],
		draft_regen_leagues_uf: state.leagues.regenerate_list.result
			.filter(l => l.id in state.leagues.regenerate_show_uf.by_id)
			.map(l => state.leagues.regenerate_show_uf.by_id[l.id]) || [],
		uf_data: selectors.getUltimateFootyData(state),
		userIsPending: state.user.user.is_pending,
	};
};

const mapDispatchToProps = {
	setGameSelectionFavourite: actions.uiGameSelection.setFavourite,
	clearGameSelectionFavourite: actions.uiGameSelection.clearFavourite,
	fetchUser: actions.fetchUser,
	// Classic
	fetchMyClassicLeagues: actions.showMyClassicLeagues,
	fetchMyClassicTeam: actions.fetchMyClassicTeam,
	fetchClassicRegenerateList: actions.regenerateListClassicLeagues,
	fetchRegenerateClassicLeague: actions.regenerateShowClassicLeague,
	// Draft
	fetchMyDraftLeagues: actions.leagueDraft.showMyDraftLeagues,
	// fetchDraftRegenerateList: actions.leagueDraft.regenerateListDraftLeagues,
	fetchRegenerateDraftLeague: actions.leagueDraft.regenerateShowDraftLeague,
	regenerateShowDraftLeagueUF: actions.leagueDraft.regenerateShowDraftLeagueUF,
	clearLeagueCreateId: actions.leagueDraft.clearUFDraftLeagueCreate
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GameSelection);