// @flow
export const IS_PRIZES_CLOSED = JSON.parse(
	process.env.REACT_APP_IS_PRIZES_CLOSED || "false"
);
export const IS_TERMS_CLOSED = JSON.parse(
	process.env.REACT_APP_IS_TERMS_CLOSED || "false"
);

export const IS_UAT = process.env.REACT_APP_GTM_ENVIRONMENT === "uat";
export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || "";
export const REACT_APP_SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || "";
export const REACT_APP_SSO_PROFILE_LINK = process.env.REACT_APP_SSO_PROFILE_LINK || "";
export const REACT_APP_FANTASY_DRAFT_URL = process.env.REACT_APP_FANTASY_DRAFT_URL || "";
export const SEASON_YEAR = Number(process.env.REACT_APP_SEASON_YEAR) || 2025;
export const IS_OFFSEASON = process.env.REACT_APP_OFF_SEASON || false;
export const IS_DISABLE_LIVE_DRAFT = JSON.parse(
	process.env.REACT_APP_DISABLE_LIVE_DRAFT || "false"
);

export const IS_HIDE_DRAFT = process.env.REACT_APP_HIDE_DRAFT || true;


export const JSON_URL = process.env.REACT_APP_JSON_URL || "";

export const WRAPPED_VIDEO_LINK = process.env.REACT_APP_WRAPPED_VIDEO_LINK || "";

export const LoadingStatus = {
	IDLE: "idle",
	LOADING: "loading",
	SUCCESS: "success",
	ERROR: "error"
};