// @flow
import * as React from "react";
import _ from "lodash";

import { ButtonPrimaryLink } from "../../ButtonPrimary";

import Container from "./Container";

type Props = {
	game_type: 'classic' | 'draft',
	league: {
		id: number,
		start_round: number,
        num_teams: number,
        size: number,
	}
}

class LeagueNotFull extends React.Component<Props> {
	render() {
		const { game_type, league } = this.props;
		const text = `H2H fixtures available once league is full ${_.get(league, "num_teams")}/${_.get(league, "size")}`;

		return <Container text={text} renderRightSide={() => (
			<ButtonPrimaryLink to={`/${game_type}/league/${league.id}/invite`}>
				Invite People
			</ButtonPrimaryLink>
		)} />;
	}
}

export default LeagueNotFull;