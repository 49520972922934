// @flow
import { createSelector } from "reselect";
import { get, some } from "lodash";
import isAuthenticated from "../../../utils/auth/isAuthenticated";
import {
	selectedDraftLeagueSelector,
	selectedClassicLeagueSelector,
	isClassicSelector,
	isDraftSelector,
	isAccountSelector,
	pathnameSelector
} from "./getSelectedLeague";

const getLeaguesNavItem = createSelector(
	pathnameSelector,
	selectedDraftLeagueSelector,
	selectedClassicLeagueSelector,
	isDraftSelector,
	isClassicSelector,
	isAccountSelector,
	(
		pathname,
		selected_draft_league,
		selected_classic_league,
		is_draft,
		is_classic,
		is_account
	) => {
		const league_defaults = {
			to: "/draft/leagues",
			title: "Select a League",
			non_link: true,
			sub_align: "right",
			show_league_switcher: true,
			sub_title: "Join or Create a new Draft league ",
			sub_items: [
				{
					to: "/draft/leagues/join",
					title: "Join a League"
				},
				{
					to: "/draft/leagues/create",
					title: "Create a League"
				}
			]
		};

		if(some([is_classic, is_account])) {
			return {
				...league_defaults,
				to: "/classic/leagues",
				title: get(selected_classic_league, "name", "Select a League"),
				sub_title: "Join or Create a new Classic league ",
				sub_items: [
					{
						to: "/classic/leagues/join",
						title: "Join a League"
					},
					{
						to: "/classic/leagues/create",
						title: "Create a League"
					}
				]
			};
		}

		return false;
	}
);


export const getRightNav = createSelector(
	pathnameSelector,
	isAuthenticated,
	getLeaguesNavItem,
	isClassicSelector,
	isAccountSelector,
	isDraftSelector,
	(
		pathname,
		is_authorized,
		leagues_nav_item,
		is_classic,
		is_account,
		is_draft
	) => {
		let nav_object = [];

		if(is_draft) {
			nav_object.push({
				to: "/draft/help/game-guidelines",
				title: "Help",
				sub_title: "Draft Help",
				sub_align: "right",
				sub_items: [
					{ to: "/draft/help/game-guidelines", title: "Game Guidelines" },
					{ to: "/draft/help/faqs", title: "FAQs" },
					{ to: "/draft/help/official-rules", title: "Official Rules" },
				]
			});
		}

		if(some([is_classic, is_account]) && is_authorized) {
			const items = [
				{
					to: "/classic/help/game-guidelines",
					title: "Help",
					sub_title: "Classic Help",
					sub_align: "right",
					sub_items: [
						{ to: "/classic/help/game-guidelines", title: "Game Guidelines" },
						{ to: "/classic/help/faqs", title: "FAQs" },
						{ to: "/classic/help/prizes", title: "Prizes" },
						{ to: "/classic/help/official-rules", title: "Official Rules" }
					]
				}

			];
			nav_object = [...nav_object, ...items];
		}


		// if not auth just return
		// or pathname isn't ready
		if(!is_authorized) {
			return nav_object;
		}

		const gameSelectionItems = [
			{
				to: "/classic/help/prizes",
				title: "Prizes"
			}, 
			{
				to: "/account",
				title: "Account",
				sub_title: "Account",
				sub_align: "right",
				sub_items: [
					{ to: "/account/my-account", title: "My Account" },
					{ to: "/account/communications", title: "Communications" },
					{ to: "/coach-subscription", title: "Fantasy Coach" },
					{ to: "/account/your-history", title: "Your History" },
					{ to: "/account/classic-history", title: "Season History"},
					{ to: "/account/contact-us", title: "Contact Us" },
					{ to: "/account/terms-and-conditions", title: "Terms and Conditions" },
					{ to: "/account/fantasy-coach-terms", title: "Fantasy Coach Terms" },
					{ to: "/logout", title: "Logout" }
				]
			}
		];

		nav_object = [...nav_object, ...gameSelectionItems];

		if(some([is_classic, is_account, is_draft])
		) {
			nav_object.unshift(leagues_nav_item);
		}

		return nav_object;
	});