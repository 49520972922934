// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import colors from "../../../assets/css/colors";
import Info from "../../Icons/Info";
import { default_transition } from "../../../assets/css/vars";
import { isMobile } from "../../../utils";

const getArrow = ({ show_on_left }) => {
	if (show_on_left) {
		return css`
			border-width: 5px 0 5px 4px;
			border-color: transparent transparent transparent ${colors.primary.accent};
			right: auto;
			left: 100%;
		`;
	}
	return css`
		border-color: transparent ${colors.primary.accent} transparent transparent;
		border-width: 5px 4px 5px 0;
		right:  100%;
		left: auto;
	`;
};
const TooltipBar = styled.div`
	display: inline-flex;
	position: relative;
	flex-basis: 5%;
	justify-content: center;
	align-items: center;

	.tooltip-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background: ${({ background }) => background};
		border-radius: 50%;
		font-size: 12px;
		height: 16px;
		width: 16px;
		font-weight: bold;
		margin-left: 10px;
		${default_transition}
		border: 1px solid ${colors.form.borderColor};

		svg {
			${default_transition}
		}
	}

	&:hover .tooltip-icon {
		border: 1px solid #ffffff;
		color: #ffffff;
		svg {
			color: #ffffff;
			fill:  ${colors.primary.accent};
		}
	}

	.tooltip-text {
		display: none;
		width: ${({ is_short }) => is_short  ?  "15em" : "20em" };
		font-size: 12px;
		background: ${colors.primary.accent};
		color: #ffffff;
		position: absolute;
		top: 50%;
		right:  ${({ show_on_left  }) => show_on_left  ?  "calc(100% + 10px)" : "auto"};
		left: ${({ show_on_left  }) => show_on_left  ? "initial" : "calc(100% + 10px)"};
		z-index: 3;
		padding: 1em;
		border-radius: 3px;
		transform: translateY(-50%);
		text-align: center;
		font-weight: 400;

		&:before {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			${getArrow};
		}
	}

		h4 {
			margin: 0 0 .5em 0;
			font-weight: bold;
			display: none;
		}

		p {
			line-height: 1.2;
			margin: 0;
			font-family: SourceSansPro;
		}

	}

	&:hover {
		.tooltip-text {
			display: block
		}
	}
`;

type Props = {
	title?: string,
	description?: string,
	background?: string,
	show_on_left?: boolean,
	show_on_right?: boolean,
	isShort?: boolean,
	className?: string,
	children?: React.Node,
}

const lockoutText = <p>
	Select what time coaches can no longer make changes to their lineups 
	(i.e. move players between on field and bench positions).	<br/>
	Rolling Lockout: Only players whose matches have started will lock out.
	Players who aren’t locked can continue to be substituted. <br/>
	Standard Lockout: All players lock out at the start of the first game of the round. 
	This applies even if the first game of the round occurs early on a Wednesday or Thursday. <br />
	Saturday Lockout: Rolling lockout up until the start of the first match on Saturday, 
	at which point all players lock out. <br/>
</p>;

export const Tooltip = ({
	title,
	description,
	background,
	show_on_left,
	show_on_right,
	isShort,
	className = "select-tooltip",
	children,
}: Props) => {
	let show_on_left_handler = isMobile() ? !show_on_right : show_on_left;
	let is_short = isMobile() ? isShort : false;
	const lockoutHandled = title === "Rolling Lockout" ? lockoutText : <p>{description}</p>;
	return(
		<TooltipBar 
			className={className} 
			background={background} 
			show_on_left={show_on_left_handler}
			is_short={is_short}>
			<div className="tooltip-text">
				<h4>{title}</h4>
				{children || lockoutHandled}
			</div>
			<span className="tooltip-icon">
				<Info color={colors.form.borderColor} size='1.5' />
			</span>
		</TooltipBar>

	);
};
	

Tooltip.defaultProps = {
	title: "",
	description: "",
	show_on_left: false,
};

export default Tooltip;