// @flow

import _, { get, keyBy } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import { default_transition } from "../../assets/css/vars";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import type { TPlayer, TPLeague, TRootStore, TRound, TSquad } from "../../modules/types";
import isMobile from "../../utils/isMobile/index";
import Ad from "../Ad";
import AdsContainer from "../AdsContainer";
import { SearchWrapper, SelectWrapper } from "../ClassicFilters";
import Footer from "../Footer";
import { Select } from "../Form";

import Close from "../Icons/Close";
import More from "../Icons/More";
import Search from "../Icons/Search";
import LeagueGameBar from "../LeagueGameBar";
import MatchCentreBar from "../MatchCentreBar";
import OneColumnLayout from "../OneColumnLayout";
import { PageTitle } from "../PageTitle";
import SearchField from "../SearchField";
import SearchInputWrapper from "../SearchInputWrapper";
import SearchSubmitButton from "../SearchSubmitButton";
import JsonFetcher from "../utils/JsonFetcher";
import withCoachesBox from "../utils/withCoachesBox";
import Lock from "../Icons/Lock";
import {
	PlayerPreviewLock,
	PlayerPreviewWrapper,
	PlayerPreviewSection,
	SectionHeader,
	FcLink,
} from "../PlayerProfilePopup/PlayerPopupStyles";
import { Exist } from "../Exist";
import { isOffSeason } from "../../utils";
import PlayerCard from "./playerCard";
import PlayerStats from "./playerStats";


const SEASON_YEAR: string = process.env.REACT_APP_SEASON_YEAR || "";

const SITE_ENV = process.env.REACT_APP_ENV || "uat";

const StyledOneColumnLayout = styled(OneColumnLayout)`
	.seo-image {
		display: none;
	}

`;

const StyledSearchWrapper = styled(SearchWrapper)`
	margin: 0;
	width: 200px;
	margin-right: 10px;
	${below.tablet`
		width: 100%;
		margin: 0 10px
	`}
`;

const StyledSelectWrapper = styled(SelectWrapper)`
	width: calc(100% - 200px);
	flex-flow: row nowrap;
	align-items: flex-start;
	${below.tablet`
		display: block;
		width: 100%;
		margin: 10px;
	`}
	> div{
		width: 120px;
		max-width: 120px;
		min-width: 120px;
		
		${below.tablet`
			width: 100%;
			max-width: 100%;
			min-width: 100%;
		`}

		> div{
			margin-top: 0;
		}
	
		&.year{
			margin-left: auto;
			width: auto;
		}
	}

`;

const ProfileForm = styled.div`
	display: flex;
	flex-flow: row wrap;
`;

const SearchResultsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	flex-wrap: wrap;
	margin-top: 10px;
	min-width: 100%;
	${below.tablet`
		margin: 0 10px;
	`}
`;
const PlayerLink = styled(Link)`
	text-decoration: none;
	background-color: ${colors.secondary.lightGrey};
	color: ${colors.buttons.loadMoreText};
	padding: 10px;
	padding-top: 12px;
	margin-right: 5px;
	margin-bottom: 5px;
	font-size: 12px;
	border-radius: 2px;

	${default_transition};

	:hover {
		background-color: ${colors.secondary.paleGrey};
	}
`;

const ButtonTransparent = styled.button`
	display: none;
	outline: none;
	padding: 0;
	background: rgba(0,0,0,0);
	border: none;
	width: 40px;
	margin: 0 0 0 10px;
	${below.tablet`
		display: block;
	`}
`;

const SearchResult = props => {
	const { player, league_id } = props;
	if (!player) {
		return null;
	}
	return (<PlayerLink to={getPlayerUrl(player.slug, league_id)}>
		{player.first_name} {player.last_name}
	</PlayerLink>);
};

const searchByName = (({ first_name, last_name }, search) => (
	`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
));

const getPlayerUrl = (slug, lid?) => lid
	? `/draft/league/${lid}/player/${slug}`
	: `/classic/player/${slug}`;

type Props = {
	player: TPlayer,
	players: {[number]: TPlayer},
	league: TPLeague,
	actual_round: TRound,
	avatar_url: string,
	is_classic?: boolean,
	match: {
		params: {
			league_id: string | number,
			player_id: string | number,
			player_slug: string
		}
	},
	history: {
		push: Function,
	},
	removeFromFavourites: Function,
	addToFavourites: Function,
	squads: Array<TSquad>,
	fetchPastYearPlayers: typeof actions.fetchPastYearPlayers,
	past_years: Object,
	fetchPlayerMatches: typeof actions.fetchPlayerMatches,
  fetchPastYearPlayerMatches: typeof actions.fetchPastYearPlayerMatches,
	fetchAllCustomStats: typeof actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: typeof actions.fetchAllCoachCustomStats,
	player_matches: Object,
	fetchTeams: typeof actions.leagueDraft.showTeamsDraftLeague,
	fetchWaiverList: typeof actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: typeof actions.leagueDraft.fetchWaiverFree,
	fetchPastYearRounds: typeof actions.fetchPastRounds,
	has_assistant_coach: boolean,
	fetchPlayerNews: typeof actions.fetchPlayerNews,
	player_news: Object,
	player_news_idle: boolean,
	players_by_id: Object
};

type State = {
	selected_squad: number,
	selected_player: string,
	redirect?: string,
	by_year: string,
	player_search: string,
	search_results: string[],
	is_filters_hidden: boolean
};

class PlayerProfileComponent extends React.Component<Props, State> {
	static defaultProps = {
		is_classic: false
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			selected_squad: -1,
			selected_player: "",
			by_year: SEASON_YEAR,
			player_search: "",
			search_results: [],
			is_filters_hidden: true
		};

		_.bindAll(this, [
			"goTo",
			"handlePlayerChange",
			"handleTeamChange",
			"updateYear",
			"toggleFilters"
		]);
	}

	componentDidMount() {
		const {
			fetchPlayerMatches,
			fetchTeams,
			fetchWaiverList,
			fetchWaiverFree,
			is_classic,
			players
		} = this.props;
		const { player_slug, league_id } = this.props.match.params;
		const player = _.find(players, { slug: player_slug });
		const player_id = _.get(player, "id");

		if (player_id) {
			fetchPlayerMatches(player_id);
		}
		this.getPlayerStats();
		
		if (!is_classic) {
			fetchTeams({
				league_id: league_id,
				details: 1
			});

			fetchWaiverList({
				league_id
			});
			fetchWaiverFree({
				league_id
			});
		}
	}

	componentDidUpdate(old_props: Props) {
		const { 
			player, 
			fetchPastYearPlayerMatches,  
			fetchPastYearRounds, 
			fetchPastYearPlayers
		} = this.props;
		const {by_year} = this.state;
		const old_player = old_props.player;
		const old_id = _.get(old_player, "id");
		const new_id = _.get(player, "id");

		if (old_player && old_player.id !== player.id) {
			this.props.fetchPlayerMatches(player.id);
			this.resetSelectedPlayer();
			this.resetSelectedSquad();
		}
		if( old_id !== new_id){
			this.props.fetchPlayerMatches(player.id);
			
		}
		if(old_id !== new_id && by_year !== `${SEASON_YEAR}`){
			fetchPastYearPlayers(by_year);
			fetchPastYearPlayerMatches({ year: by_year, player_id: new_id });
			fetchPastYearRounds( by_year);
		}

		/*
		putting it together with coach json
		const playerNewsUpdated = 
			old_props.player_news_idle !== player_news_idle;
		if(!playerNewsUpdated) {
			this.getPlayerNews();
		}
		*/
		this.manageSelectForm();

		if(old_props.league !== this.props.league){
			this.getPlayerStats();
		}
	}

	clearSearch() {
		this.setState({
			player_search: "",
			search_results: []
		});
	}

	getPlayerStats() {
		const {
			fetchAllCustomStats,
			fetchAllCoachCustomStats,
			league,
			match: { params: { league_id } },
			has_assistant_coach,
		} = this.props;
		if(league && Boolean(league.custom_scoring_enabled)){
			fetchAllCustomStats(league_id);
			if(has_assistant_coach){
				fetchAllCoachCustomStats(league_id);
			}
		}
	}

	resetSelectedPlayer() {
		const { player } = this.props;
		const new_selected = player.id;
		if (new_selected) {
			this.setState({
				selected_player: new_selected
			});
		}
	}

	resetSelectedSquad() {
		const { player, squads } = this.props;
		const player_squad = _.find(squads, { id: player.squad_id });
		if (player_squad) {
			this.setState({
				selected_squad: player_squad.id
			});
		}
	}

	manageSelectForm() {
		const { selected_squad, selected_player } = this.state;
		const { player } = this.props;

		if (selected_squad === -1 && player !== undefined) {
			this.resetSelectedSquad();
		}

		if (selected_player === "" && player !== undefined) {
			this.resetSelectedPlayer();
		}
	}

	get playerName() {
		const { player } = this.props;
		if (player === undefined) {
			return "";
		}
		return `${player.first_name} ${player.last_name}`;
	}

	get playerSquadName() {
		const {player, squads} = this.props;
		const squad_id = get(player, 'squad_id', 0);
		const playerSquad = squads.find(squad => squad.id === squad_id);
		return get(playerSquad, "full_name", "");
	}

	get squad_players() {
		const { players } = this.props;
		const { selected_squad } = this.state;

		if (selected_squad) {
			return _.filter(players, { squad_id: selected_squad });
		}

		return [];
	}



	goTo(url) {
		const { history } = this.props;

		history.push(url);
	}

	handleTeamChange(e) {
		this.setState({
			selected_squad: parseInt(e.target.value, 10)
		});
	}

	handlePlayerChange(e) {
		const new_player = e.target.value;
		const {
			match: { params: { league_id } },
			fetchPlayerMatches, player_matches,
			players_by_id
		} = this.props;
		const player = get(players_by_id, new_player);
		const slug = get(player, 'slug', "");

		this.setState({
			selected_player: new_player
		});

		if (!player_matches.hasOwnProperty(parseInt(new_player, 10))) {
			fetchPlayerMatches(new_player);
		}

		this.goTo(getPlayerUrl(slug, league_id));
	}

	handlePlayerSearchChange(e) {
		const new_search = e.currentTarget.value;
		const { players } = this.props;
		let search_results = [];

		if (new_search.length >= 3) {
			search_results = _(players)
				.filter(p => searchByName(p, new_search))
				.value();
		}

		this.setState({
			player_search: new_search,
			search_results
		});
	}

	toggleFilters() {
		this.setState({
			is_filters_hidden: !this.state.is_filters_hidden
		});
	}

	get profileForm() {
		const { squads } = this.props;
		const { squad_players } = this;
		const {
			selected_squad, 
			selected_player, 
			player_search, 
			search_results, 
			is_filters_hidden, 
			by_year,
		} = this.state;
		const { league_id } = this.props.match.params;
		const className = isMobile() && is_filters_hidden ? "hidden-mobile" : "";
		return (
			<ProfileForm>
				<StyledSearchWrapper>
					<SearchInputWrapper>
						<SearchField
							type="text"
							name="search"
							component="input"
							placeholder="Search for a player"
							value={player_search}
							onChange={e => this.handlePlayerSearchChange(e)}
						/>
						<SearchSubmitButton
							type="button"
							onClick={() => this.clearSearch()}
						>
							{player_search
								? <Close />
								: <Search />
							}
						</SearchSubmitButton>
					</SearchInputWrapper>
					<ButtonTransparent onClick={this.toggleFilters}>
						{is_filters_hidden ? (
							<More width={20} unit={"px"} />
						) : (
							<Close color="#1D4073" width={"24px"} height={"24px"} />
						)}
					</ButtonTransparent>
				</StyledSearchWrapper>
				<StyledSelectWrapper className={className}>
					<div>
						<Select
							name="by_squad" is_disabled={false}
							value={selected_squad ? selected_squad : ""}
							onChange={e => this.handleTeamChange(e)}
						>
							{squads.map(({ id, full_name }) => (
								<option key={id} value={id}>
									{full_name}
								</option>
							))}
						</Select>
					</div>
					<div>
						<Select
							name="by_player" is_disabled={false} value={selected_player}
							onChange={e => this.handlePlayerChange(e)}
						>
							{
								_(squad_players)
									.orderBy(p => p.last_name)
									.map(({ id, first_name, last_name }) => (
										<option key={id} value={id}>
											{first_name} {last_name}
										</option>
									))
									.value()
							}
						</Select>
					</div>
					<Exist when={!isOffSeason()}>
						<div className='year'>
							<Select
								name="by_year"
								is_disabled={false}
								value={by_year}
								onChange={e => this.updateYear(e)}
							>
								{_.range(SEASON_YEAR, 2014, -1).map(year => (
									<option key={year} value={year}>
										{year}
									</option>
								))}
							</Select>
						</div>
					</Exist>
					
				</StyledSelectWrapper>
				<SearchResultsWrapper>
					{
						_.map(search_results, player => (
							<SearchResult
								key={player.id}
								player={player}
								league_id={league_id}
							/>
						))
					}

				</SearchResultsWrapper>
			</ProfileForm>
		);
	}

	get player_preview_section() {
		const {has_assistant_coach, player, player_news} = this.props;
		const playerStatus = _.get(player, "status");
		const text = _.get(player_news, "text");
		const HIDE_PREVIEW = SITE_ENV === "production";
		if(HIDE_PREVIEW){
			return null;
		}
		if(!has_assistant_coach){
			return <PlayerPreviewWrapper>
				<SectionHeader isFullProfile is_coach>Player Preview</SectionHeader>
				<PlayerPreviewLock>
					<FcLink href={"/coach-subscription"} target="_blank">
						<Lock size={1.5} color={colors.coach}/>
					</FcLink>
					<p>
						Subscribe to see what Fantasy Coach says about {" "}
						{this.playerName} for this round!
					</p>
				</PlayerPreviewLock>
			</PlayerPreviewWrapper>;
		}
		if(playerStatus === "uncertain"){
			return <PlayerPreviewWrapper>
				<SectionHeader isFullProfile is_coach>Player Preview</SectionHeader>
				<PlayerPreviewSection>
					Player Preview will be available once teams are announced this week.
				</PlayerPreviewSection>
			</PlayerPreviewWrapper>;
		}
		return <PlayerPreviewWrapper>
			<SectionHeader isFullProfile is_coach>Player Preview</SectionHeader>
			<PlayerPreviewSection>
				{text}
			</PlayerPreviewSection>
		</PlayerPreviewWrapper>;
	}

	updateYear(e) {
		const { 
			past_years, 
			fetchPastYearPlayers, 
			fetchPastYearPlayerMatches, 
			fetchPastYearRounds 
		} = this.props;

		const { selected_player } = this.state;

		const by_year = e.currentTarget.value;

		const alreadyFetched = past_years.hasOwnProperty(by_year);

		if(by_year !== `${SEASON_YEAR}` && !alreadyFetched) {
			fetchPastYearPlayers(by_year);
			fetchPastYearPlayerMatches({ year: by_year, player_id: selected_player });
			fetchPastYearRounds( by_year);
		}

		this.setState({
			by_year
		});
	}

	render(){
		const classic_json = ["rounds", "players", "venues", "squads", "coach_players"];
		const draft_json = [];

		const { player, league, actual_round, is_classic, avatar_url } = this.props;

		const { by_year, redirect } = this.state;  
		  

		if (redirect) {
			return <Redirect to={redirect} />;
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>{this.playerName}'s AFL Fantasy Player Profile 
					</title>
       				<meta 
						name="description" 
						content={`AFL Fantasy's official profile for ${this.playerName}. 
						Includes current and previous year's data against 
						all teams and at all venues`} 
					/>	
					<meta name="keywords" 
						content={`${this.playerName}, AFL, ${this.playerSquadName}`} />
				</Helmet>
				
				<JsonFetcher fetch={is_classic ? classic_json : [...classic_json, ...draft_json]} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<MatchCentreBar />
				<StyledOneColumnLayout>
					<img src={avatar_url} 
						alt={`${this.playerName} - ${this.playerSquadName}`} className="seo-image"/>
					<PageTitle>
						Player profile for {this.playerName}
					</PageTitle>
					{this.profileForm}
					<PlayerCard
						player={player}
						league={league}
						actual_round={actual_round}
						is_classic={is_classic}
						by_year={by_year}
					/>
					<Exist when={!isOffSeason()}>
						{this.player_preview_section}
					</Exist>
					
					<PlayerStats 
						player={player} 
						league={league} 
						is_classic={is_classic} 
						by_year={by_year} 
					/>
				</StyledOneColumnLayout>
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => {
	const { is_classic } = props;
	const { player_slug } = props.match.params;
	if(!is_classic && !_.get(props, "match.params.league_id")) {
		return {};
	}
	
	const methodName = is_classic ? "getExtendedPlayersArray" : "getExtendedPlayersArrayWithOwners";
	const players = selectors.players[methodName](state, props);
	const PLAYERS_AVATAR_PATH = (process.env.REACT_APP_PLAYERS_PATH);

	const past_players = selectors.getExtendedPlayersWithPastYearsClassic(state, props);
	const league = is_classic ? null : selectors.leagueDraft.getLeague(state, props);
	const actual_round = selectors.rounds.getActualRound(state);
	const player = _.find(players, { slug: player_slug });
	const players_by_id = keyBy(players, 'id');
	const squads = selectors.squads.getSquads(state);
	const past_years = state.players.past_years;
	const player_matches = state.players.matches_by_id;
	const player_squad = _.get(player, "squad_id");
	const player_id = _.get(player, "id");
	const getFromPlayer = _.partial(_.get, player, _);

	const avatar_url = `${PLAYERS_AVATAR_PATH + getFromPlayer("id")}_450.png`;
	
	return {
		league,
		player,
		actual_round,
		squads,
		players,
		past_years,
		player_matches,
		players_by_id,
		past_players,
		avatar_url,
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		player_news: selectors.playerNews.getPlayerSpecificNews(state)(player_id, player_squad),
		player_news_idle: selectors.playerNews.isPlayerSpecificNewsIdle(state)
	};
};

const mapDispatchToProps = {
	addToFavourites: actions.postAddToFavourites,
	removeFromFavourites: actions.postRemoveFromFavourites,
	fetchPastYearPlayers: actions.fetchPastYearPlayers,
	fetchPastYearPlayerMatches: actions.fetchPastYearPlayerMatches,
	fetchPlayerMatches: actions.fetchPlayerMatches,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: actions.fetchAllCoachCustomStats,
	fetchTeams: actions.leagueDraft.showDraftOrder,
	fetchWaiverList: actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchPastYearRounds: actions.fetchPastRounds,
	fetchPlayerNews: actions.fetchPlayerNews
};

export const PlayerProfile = compose(
	withRouter,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(PlayerProfileComponent);

export default PlayerProfile;