// @flow
import { Redirect } from "react-router-dom";
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import { league_tooltips } from "../../../helpers";
import { getShortName } from "../../../helpers/league";
import type { TPLeague } from "../../../modules/types";
import { below } from "../../../assets/css/media";

import {
	Ad,
	AdsContainer,
	ButtonPrimary,
	ConfirmationModal,
	Footer,
	LeagueAvatar,
	LeagueGameBar,
	PageContentWrapper,
	PageTitle,
	Preloader,
	StandardSidebar,
	TwoColumnLayout,
} from "../../../components";

import colors from "../../../assets/css/colors";
import LeagueItem from "./leagueAbout/leagueItem";

const AboutPageStyled = styled.div`
	border: 1px solid #ecf1f5;
	margin: 1em 0;
	background: ${colors.form.base};
	box-sizing: border-box;
	padding: 0 60px;

	${below.tablet`
		padding: 0 20px;
	`};

	button {
		display: block;
		width: 290px;
		margin: 0 auto;
	}

	svg {
		width: 1.1em;
	}
`;

const AvatarWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100px;
	color: ${colors.form.textColor};
	margin: 3em 0;

	picture {
		margin: 1em .5em .5em 0;
	}
`;

const LeagueName = styled.h3`
	color: ${colors.primary.primary};
	font-size: 28px;
	margin-bottom: .2em;
	line-height: 1.3;
	font-weight: bold;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DataWrapper = styled.div`
	max-width: 484px;
	margin: 0;

	label {
		margin: 0 0 0.2em;
	}
`;

const SubTitle = styled.h4`
	display: flex;
	align-items: center;
	color: ${colors.primary.primary};
	font-weight: bold;
	margin: 30px 0 20px;

	div {
		margin-right: .5em;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1em 0;
`;

const UserName = styled.div`
	color: ${colors.form.textColor};
	font-weight: bold;
`;

type Props = {
	showClassicLeague: typeof actions.showClassicLeague,
	leaveClassicLeague: typeof actions.leaveClassicLeague,
	match: {
		params: {
			league_id: number,
		}
	},
	league_show: {
		error: string,
		league_id: number,
	},
	league: TPLeague,
	user: {
		id: number,
	}
}

type State = {
	show_leave_modal: boolean,
	left_the_league: boolean,
};

class LeagueAboutComponent extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"leaveThisLeague",
			"confirmLeaveleague",
			"closeLeaveModal",
		]);
	}

	state = {
		show_leave_modal: false,
		left_the_league: false,
	};

	componentDidMount() {
		window.scrollTo(0, 0);

		const {
			match: {
				params: {
					league_id,
				}
			},
			showClassicLeague,
		} = this.props;

		showClassicLeague({ id: league_id });
	}

	confirmLeaveleague() {
		const { leaveClassicLeague, league: { id } } = this.props;
		leaveClassicLeague({ league_id: id });
		this.setState({ left_the_league: true });
	}

	leaveThisLeague() {
		this.setState({ show_leave_modal: true });
	}

	closeLeaveModal() {
		this.setState({ show_leave_modal: false });
	}

	get leave_modal() {
		return (
			<ConfirmationModal
				header_text='Leave League'
				body_text='Are you sure you want to leave this league?'
				confirmClick={this.confirmLeaveleague}
				cancelClick={this.closeLeaveModal}
				closeClick={this.closeLeaveModal}
			/>
		);
	}

	get league_settings() {
		const { league } = this.props;

		const leagueTooltipsName = {...league_tooltips.name, show_on_right: false, isShort: true};
		const leagueTooltipsPrivacy = {...league_tooltips.privacy, show_on_right: true, 
			isShort: true};
		const leagueTooltipsType = {...league_tooltips.type, show_on_right: true, isShort: true};
		const leagueTooltipsSize = {...league_tooltips.size, show_on_right: true, isShort: true};
		const leagueTooltipsStartRound = {...league_tooltips.start_round, show_on_right: true, 
			isShort: true};

		return (
			<div>
				<LeagueItem
					name='League Name'
					content={league.name}
					tooltip={leagueTooltipsName}
				/>
				<LeagueItem
					name='Privacy Settings'
					content={league.privacy ? "Public" : "Private"}
					tooltip={leagueTooltipsPrivacy}
				/>
				<LeagueItem
					name='League type'
					content={league.type === "head_to_head" ? "Head to Head" : "Open"}
					tooltip={leagueTooltipsType}
				/>
				{league.type === "head_to_head" ? (
					<LeagueItem
						name='League Size'
						content={league.size}
						tooltip={leagueTooltipsSize}
					/>
				): null}
				<LeagueItem
					name='League Start Round'
					content={league.start_round}
					tooltip={leagueTooltipsStartRound}
				/>
			</div>
		);
	}

	get advanced_settings() {
		const { league } = this.props;

		return(
			<div>
				<LeagueItem
					name='League Finals Series'
					content={league.finals ? "Yes" : "No"}
					tooltip={league_tooltips.finals}
				/>

				{league.finals ? (
					<LeagueItem
						name='League Finals Format'
						content={"TOP "+league.finals_format}
						tooltip={league_tooltips.finals_format}
					/>
				): null}

				<LeagueItem
					name='Play Opponents'
					content={league.play_times}
					tooltip={league_tooltips.play_times}
				/>
				<LeagueItem
					name='Bye Rounds'
					content={league.bye_rounds ? "On" : "Off"}
					tooltip={league_tooltips.bye_rounds}
				/>
				<LeagueItem
					name='Ladder Tiebreaker'
					content={league.rank_by === "percentage" ? "Percentage" : "Points For"}
					tooltip={league_tooltips.ladder_tiebreaker}
				/>
			</div>
		);
	}

	checkAccessToLeague() {
		const {
			league_show: {
				league_id
			},
			user: {
				id: user_id
			},
			league,
		} = this.props;

		if(league && league.commissioner && league.commissioner === user_id) {
			return <Redirect to={`/classic/league/${league_id}/settings`} />;
		}
		else {
			return null;
		}
	}

	get remove_button() {
		const {
			league,
		} = this.props;

		return league.status === "scheduled" && league.class !== "club" ? (
			<ButtonWrapper>
				<ButtonPrimary onClick={this.leaveThisLeague}>
					LEAVE THIS LEAGUE
				</ButtonPrimary>
			</ButtonWrapper>
		) : null;
	}

	render() {
		const {
			league,
			league_show,
		} = this.props;

		const {
			show_leave_modal,
			left_the_league,
		} = this.state;

		if (!league) {
			return <Preloader />;
		}

		return (
			<div>
				{this.checkAccessToLeague()}
				{league_show.error ? <Redirect to='/classic/leagues' /> : null}
				{left_the_league ? <Redirect to='/classic/leagues' /> : null}
				{show_leave_modal ? this.leave_modal : null}
				<LeagueGameBar no_fetch={true}/>
				<AdsContainer>
					<Ad id="about" />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitle>
						About League
					</PageTitle>
					<TwoColumnLayout>
						<AboutPageStyled>
							<AvatarWrapper>
								<LeagueAvatar is_classic league={league} size="80px" />
								<div>
									<LeagueName>{league.name}</LeagueName>
									<UserName>{getShortName(league)}</UserName>
								</div>
							</AvatarWrapper>
							<DataWrapper>
								{this.league_settings}

								{league.type === "head_to_head" ? (
									<div>
										<SubTitle>
										Advanced Settings
										</SubTitle>
										{this.advanced_settings}
									</div>
								) : null}
							</DataWrapper>

							{this.remove_button}

						</AboutPageStyled>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	league_show: state.leaguesClassic.show,
	league: selectors.leaguesClassic.show.getLeague(state, props),
	league_leave: state.leaguesClassic.leave,
	user: state.user.data,
});

const mapDispatchToProps = {
	showClassicLeague: actions.showClassicLeague,
	leaveClassicLeague: actions.leaveClassicLeague,
};

export const LeagueAbout = connect(
	mapStateToProps,
	mapDispatchToProps
)(LeagueAboutComponent);

export default LeagueAbout;