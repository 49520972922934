// @flow
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import afl from "../../assets/images/telstra/afl.svg";
import aflW from "../../assets/images/telstra/aflW.svg";
import playLogo from "../../assets/images/telstra/playLogo.svg";
import playLogoMobile from "../../assets/images/telstra/playLogoMobile.svg";
import adelaideLogo from "../../assets/images/telstra/adelaide.svg";
import brisbaneLogo from "../../assets/images/telstra/brisbane.svg";
import carltonLogo from "../../assets/images/telstra/carlton.svg";
import collingwoodLogo from "../../assets/images/telstra/collingwood.svg";
import essendonLogo from "../../assets/images/telstra/essendon.svg";
import fremantleLogo from "../../assets/images/telstra/fremantle.svg";
import geelongLogo from "../../assets/images/telstra/geelong.svg";
import gcsunsLogo from "../../assets/images/telstra/gcsuns.svg";
import gwsLogo from "../../assets/images/telstra/gws.svg";
import hawthornLogo from "../../assets/images/telstra/hawthorn.svg";
import melbourneLogo from "../../assets/images/telstra/melbourne.svg";
import northMelbourneLogo from "../../assets/images/telstra/northmelbourne.svg";
import portAdelaideLogo from "../../assets/images/telstra/portAdelaide.svg";
import richmondLogo from "../../assets/images/telstra/richmond.svg";
import stkildaLogo from "../../assets/images/telstra/stkilda.svg";
import sydneyLogo from "../../assets/images/telstra/sydney.svg";
import wceLogo from "../../assets/images/telstra/wce.svg";
import bulldogsLogo from "../../assets/images/telstra/bulldogs.svg";
// import { ReactComponent as TabMask } from "../../assets/images/telstra/tab.svg";
import telstraBlue from "../../assets/images/telstra/telstraBlue.svg";
import telstraWhite from "../../assets/images/telstra/telstraWhite.svg";
import telstraWhiteOnly from "../../assets/images/telstra/telstraWhiteOnly.svg";
import telstraBg from "../../assets/images/telstra/telstraDropdownBg.jpg";
import AddIconNew from "../../components/Icons/AddIconNew.js";
import { Exist } from "../Exist";
import { below } from "../../assets/css/media";
import { isBelow } from "../../utils";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(3, 23, 69);
  min-height: 48px;
  padding-left: 8px;
  z-index: 120;
  position: relative;
  @media (min-width: 1160px) {
      background-color: white;
  }
`;

const NameLinkSection = styled.div`
  display: flex;
  height: 48px;
  align-items: flex-end;
  width: 30%;
    ${below.field_view_mid`
       width: 100%;
	`};
  `;

const AFLNameSection = styled.a`
	text-size-adjust: 100%;
	list-style-type: none;
	line-height: 4.2rem;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	border-radius: 5px 5px 0 0;
	padding: 0 0.5rem;
	transition: opacity 0.25s ease;
	text-decoration: none;
	justify-content: center;
	gap: 0.8rem;
	opacity: 1;
	background: linear-gradient(180deg, rgb(0 64 153/25%), transparent),
		linear-gradient(0deg, #01285e, #01285e);

	display: flex;
	align-items: center;
	width: 33%;
	max-width: 146px;
	height: 42px;
	background: #023680;
	svg {
		width: 35px;
		height: auto;
	}
	font-size: 13.3px;
	color: white;
	font-weight: bold;
	&:hover {
		background: linear-gradient(180deg, rgb(0 64 153 / 75%), transparent),
			linear-gradient(0deg, #01285e, #01285e);
	}
	cursor: pointer;
    ${below.tablet`
       max-width: 72px;
	`};
`;

// noinspection CssUnusedSymbol
const InactiveNameSection = styled.a`
	text-size-adjust: 100%;
	list-style-type: none;
	line-height: 4.2rem;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	border-radius: 5px 5px 0 0;
	padding: 0 0.5rem;
	align-items: center;
	transition: opacity 0.25s ease;
	text-decoration: none;
	display: flex;
	justify-content: center;
	gap: 0.8rem;
	opacity: 1;
	width: 33%;
	max-width: 146px;
	height: 42px;
	font-size: 13.3px;
	font-weight: bold;
	cursor: pointer;
	background: white;
	color: #767676;
	svg {
		width: 28px;
		height: auto;
	}
	&.play {
		svg {
			width: 61px;
			height: auto;
		}
            
	}
	&:hover {
		background: rgba(0, 0, 0, 0.09);
	}
    ${below.field_view_mid`   
        background: rgba(0, 0, 0, 0.09);
        svg > path {
            fill: currentColor;
        }
        &.play {
            svg {
                width: 32px;
                height: auto;
            }   
        }
    `};
    ${below.tablet`
       max-width: 72px;
	`};
`;



const ClubsLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    gap: 4px;
    justify-content: space-around;
`;

const ClubWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	width: 32px;
	.name-span {
		display: none;
        align-items: center;
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		.name-span {
			display: flex;
		}
	}
`;


// noinspection CssUnusedSymbol
const ClubLink = styled.a`
	.logo-expand {
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		.logo-expand {
			transform: scale(1.4);
		}
	}
`;

const ClubLogoInnerSpan = styled.span`
	text-size-adjust: 100%;
	visibility: visible;
	list-style-type: none;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	display: flex;
	justify-content: center;
	color: white;
	padding: 4px 6px;
	background-color: #000;
	text-decoration: none;
	font-weight: 700;
	width: fit-content;
	font-size: 14px;
	height: 36px;
	position: absolute;
	top: 32px;
	border-radius: 8px;
	line-height: 18px;
	text-align: center;
	white-space: nowrap;
	svg {
        margin-left: 4px;
		width: 18px;
		height: 18px;
	}
`;

// const MobileClubLogoInnerSpan = styled.span`
// 	text-size-adjust: 100%;
// 	visibility: visible;
// 	list-style-type: none;
// 	box-sizing: border-box;
// 	-webkit-font-smoothing: antialiased;
// 	display: flex;
// 	justify-content: center;
// 	color: white;
// 	padding: 2px 4px;
// 	background-color: #000;
// 	text-decoration: none;
// 	font-weight: 500;
// 	width: fit-content;
// 	font-size: 12px;
// 	height: 24px;
// 	position: absolute;
// 	top: 60px;
// 	border-radius: 8px;
// 	line-height: 14px;
// 	text-align: center;
// 	white-space: nowrap;
// `;


const ScreenReaderSpan = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
`;

const PlainButton = styled.button`
	display: flex;
	align-items: center;
	min-width: 48px;
    min-height: 20px;
	height: 100%;
	padding: 0 24px 0 12px;
	border: none;
	position: relative;
	background: transparent;

    		${below.field_view_mid`
	    padding: 0 12px 0 6px;
		`}

	.telstra-blue {
		display: flex;
		height: 24px;
		width: 24px;
		margin-right: 4px;

		${below.field_view_mid`
			display: none;
		`}
	}

	.telstra-white {
		margin-right: 4px;
		display: none;

		${below.field_view_mid`
			height: 20px;
			width: 20px;
            flex-shrink: 0;
			display: flex;
		`}
	}
`;

const ClubSitesMobileButton = styled.button`
	display: flex;
	align-items: center;
	// min-width: 48px;
	height: 100%;
	padding: 0 6px;
	border: none;
	position: relative;
	background: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
    gap: 4px;
    min-height: 20px;
`;

const StyledVerticalDivider = styled.div`
	border-left: 0.5px solid rgba(255,255,255,0.2);
	height: 20px;
    margin: 0 4px 0 8px;
`;

const CreatedByTelstraText = styled.div`
	display: flex;
	flex-direction: column;
	height: 32px;
	justify-content: center;

	.created-by-text {
		color: rgba(82, 82, 82);
		font-weight: 500;
		font-size: 8px;
		letter-spacing: 1.4px;
	}

	.telstra-text {
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1.4px;
	}

	${below.field_view_mid`
		display: none;
	`}
`;

const Triangle = styled.div`
	width: 0;
	height: 0;
	margin-left: 4px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #767676;
	transition: transform 0.2s ease;
	transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};

	${below.field_view_mid`
		margin-left: 2px;
	`}
`;

const TelstraDropdown = styled.div`
	width: 288px;
	position: absolute;
	top: 28px;
	right: 6px;
	border-radius: 8px;
	box-shadow:
		0 0 10px rgba(0, 0, 0, 0.06),
		0 10px 30px rgba(0, 0, 0, 0.2);
`;

const MobileClubsDropDown = styled.div`
    width: 240px;
    height: 432px;
    position: absolute;
    top: 28px;
    right: 6px;
    border-radius: 8px;
    background: #fff;
    box-shadow:
        0 0 10px rgba(0, 0, 0, 0.06),
        0 10px 30px rgba(0, 0, 0, 0.2);
`;

const MobileClubsWrapper = styled.div`
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    height: 100%;
    width: 100%;
    padding: 0 8px;
    
`;

const MobileClubLink = styled.a`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: 56px;
        width: 56px;
    }
`;

const MobileClubWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // .name-span {
	// 	display: none;
    //     align-items: center;
	// 	transition: all 0.2s ease-in-out;
	// }

    .logo-color {
        border-radius: 4px;
	}

	&:hover {
		// .name-span {
		// 	display: flex;
		// }
        .logo-color {
            background-color: rgba(0,64,153,0.08);
        }
	}
`;


const InnewDropdown = styled.div`
	width: 100%;
	padding: 24px;
	height: 144px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: url(${telstraBg});
	background-size: cover;
	background-repeat: no-repeat;
	color: white;

	svg {
		width: 40px;
		height: 40px;
		margin-bottom: 16px;
	}

	.subtext-1 {
		font-weight: 700;
		line-height: 24px;
		margin-bottom: 2px;
	}

	.subtext-2 {
		font-size: 13px;
		line-height: 20px;
	}
`;

const InnerLowerDropdown = styled.ul`
	display: flex;
	background: white;
	flex-direction: column;
	padding: 8px;
	width: 100%;
	list-style-type: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	li {
		list-style-type: none;
		cursor: pointer;
		height: 52px;
		width: 100%;

		&:hover {
			background: #00409914;

			a {
				color: #004099;
			}
		}

		a {
			color: #000000;
			text-decoration: none;
			height: 100%;
			width: 100%;
			font-size: 16px;
			font-weight: 700;
			display: flex;
			padding-left: 16px;
			padding-right: 16px;
			align-items: center;
			justify-content: space-between;
		}

		svg {
			fill: #01285e;
			height: 20px;
			width: 20px;

			&:hover {
				a {
					fill: #004099;
				}
			}
		}
	}
`;

const Afl = styled.img.attrs({
	src: afl,
})`
    height: auto;
    width: 35px;
`;

const AflW = styled.img.attrs({
	src: aflW,
})`
    height: auto;
    width: 35px;
`;

const PlayLogo = styled.img.attrs({
	src: playLogo,
})`
    height: 32px;
    width: auto;
`;

const PlayLogoMobile = styled.img.attrs({
	src: playLogoMobile,
})`
    height: 16px;
    width: auto;
`;

const TelstraBlueImg = styled.img.attrs({
	src: telstraBlue,
})`
    height: 24px;
    width: 24px;
`;

const TelstraWhiteImg = styled.img.attrs({
	src: telstraWhite,
})`
    height: 40px;
    width: 40px;
`;

const TelstraWhiteOnlyImg = styled.img.attrs({
	src: telstraWhiteOnly,
})`
    height: auto;
    width: 20px;
`;


// Club data
const clubLogos = [
	{
		logo: adelaideLogo,
		name: "Adelaide Crows",
		link: "https://www.afc.com.au/",
	},
	{
		logo: brisbaneLogo,
		name: "Brisbane",
		link: "https://www.lions.com.au/",
	},
	{
		logo: carltonLogo,
		name: "Carlton",
		link: "https://www.carltonfc.com.au/",
	},
	{
		logo: collingwoodLogo,
		name: "Collingwood",
		link: "https://www.collingwoodfc.com.au/",
	},
	{
		logo: essendonLogo,
		name: "Essendon",
		link: "https://www.essendonfc.com.au/",
	},
	{
		logo: fremantleLogo,
		name: "Fremantle",
		link: "https://www.fremantlefc.com.au/",
	},
	{
		logo: geelongLogo,
		name: "Geelong",
		link: "https://www.geelongcats.com.au/",
	},
	{
		logo: gcsunsLogo,
		name: "Gold Coast Suns",
		link: "https://www.goldcoastfc.com.au/",
	},
	{
		logo: gwsLogo,
		name: "GWS Giants",
		link: "https://www.gwsgiants.com.au/",
	},
	{
		logo: hawthornLogo,
		name: "Hawthorn",
		link: "https://www.hawthornfc.com.au/",
	},
	{
		logo: melbourneLogo,
		name: "Melbourne",
		link: "https://www.melbournefc.com.au/",
	},
	{
		logo: northMelbourneLogo,
		name: "North Melbourne",
		link: "https://www.nmfc.com.au/",
	},
	{
		logo: portAdelaideLogo,
		name: "Port Adelaide",
		link: "https://www.portadelaidefc.com.au/",
	},
	{
		logo: richmondLogo,
		name: "Richmond",
		link: "https://www.richmondfc.com.au/",
	},
	{
		logo: stkildaLogo,
		name: "St Kilda",
		link: "https://www.saints.com.au/",
	},
	{
		logo: sydneyLogo,
		name: "Sydney Swans",
		link: "https://www.sydneyswans.com.au/",
	},
	{
		logo: wceLogo,
		name: "West Coast Eagles",
		link: "https://www.westcoasteagles.com.au/",
	},
	{
		logo: bulldogsLogo,
		name: "Western Bulldogs",
		link: "https://www.westernbulldogs.com.au/",
	},
];

const TELSTRA_LINKS = [
	{
		title: "AFL Tipping",
		link: "https://tipping.afl.com.au/",
	},
	{
		title: "AFL Fantasy",
		link: "https://www.afl.com.au/fantasy/",
	},
	{
		title: "Telstra",
		link: "http://media.telstra.com.au/home.html",
	},
	{
		title: "My Telstra",
		link: "https://telstra.app.link/iDI7aOSJKHb",
	},
	{
		title: "Foxtel From Telstra",
		link: "https://www.telstra.com.au/entertainment/tv-movies/foxtel-from-telstra",
	},
];

type Props = {};

type State = {
    telstraDropdownOpen: boolean,
    mobileClubsDropdownOpen: boolean,
    is_below_768: boolean,
    is_below_1160: boolean
};


class AFLHeader extends React.Component<Props, State> {
    telstraButtonRef: { current: null | HTMLElement };
    mobileClubsButtonRef: { current: null | HTMLElement };

    constructor(props: Props) {
    	super(props);
    	this.state = {
    		telstraDropdownOpen: false,
    		mobileClubsDropdownOpen: false,
    		is_below_768: isBelow(768),
    		is_below_1160: isBelow(1160)
    	};

    	_.bindAll(this, [
    		"toggleTelstraDropdown",
    		"closeTelstraDropdown",
    		"toggleMobileClubsDropdown",
    		"closeMobileClubsDropdown",
    		"resize",
    		"handleOutsideClick"
    	]);

    	this.telstraButtonRef = React.createRef();
    	this.mobileClubsButtonRef = React.createRef();
    }

    componentDidMount() {
    	document.addEventListener("click", this.handleOutsideClick);
    	window.addEventListener("resize", this.resize);
    	this.resize();
    }

    componentWillUnmount() {
    	document.removeEventListener("click", this.handleOutsideClick);
    	window.removeEventListener("resize", this.resize);
    }

    // handleOutsideClick = (event) => {
    //     if (
    //         this.state.telstraDropdownOpen &&
    //         this.telstraButtonRef.current &&
    //         !this.telstraButtonRef.current.contains(event.target)
    //     ) {
    //         this.closeTelstraDropdown();
    //     }
    // };

    handleOutsideClick = (event: MouseEvent) => {
    	const target = ((event.target: any): Node);

    	if (
    		this.state.telstraDropdownOpen &&
            this.telstraButtonRef.current &&
            !this.telstraButtonRef.current.contains(target)
    	) {
    		this.closeTelstraDropdown();
    	}

    	if (
    		this.state.mobileClubsDropdownOpen &&
            this.mobileClubsButtonRef.current &&
            !this.mobileClubsButtonRef.current.contains(target)
    	) {
    		this.closeMobileClubsDropdown();
    	}
    };


    toggleTelstraDropdown() {
    	this.setState(prevState => ({
    		telstraDropdownOpen: !prevState.telstraDropdownOpen
    	}));
    }

    closeTelstraDropdown() {
    	this.setState({
    		telstraDropdownOpen: false
    	});
    }

    toggleMobileClubsDropdown() {
    	this.setState(prevState => ({
    		mobileClubsDropdownOpen: !prevState.mobileClubsDropdownOpen
    	}));
    }

    closeMobileClubsDropdown() {
    	this.setState({
    		mobileClubsDropdownOpen: false
    	});
    }

    resize() {
    	this.setState({
    		is_below_768: isBelow(768),
    		is_below_1160: isBelow(1160)
    	});
    }

    render() {
    	const { telstraDropdownOpen, mobileClubsDropdownOpen, is_below_768, is_below_1160 } = this.state;

    	return (
    		<HeaderWrapper>
    			<NameLinkSection>
    				<AFLNameSection
    					href="https://www.afl.com.au/"
    					aria-label="AFL"
    					target="_blank">
    					<Afl />
    					<Exist when={!is_below_768}>AFL</Exist>
    				</AFLNameSection>
    				<InactiveNameSection
    					aria-label="AFLW"
    					href="https://www.afl.com.au/aflw"
    					target="_blank">
    					<AflW />
    					<Exist when={!is_below_768}>AFLW</Exist>
    				</InactiveNameSection>
    				<InactiveNameSection
    					className="play"
    					href="https://www.afl.com.au/aflplay"
    					aria-label="AFLPlay"
    					target="_blank">
    					<Exist when={!is_below_1160}><PlayLogo /></Exist>
    					<Exist when={is_below_1160}><PlayLogoMobile /></Exist>
    				</InactiveNameSection>
    			</NameLinkSection>

    			<Exist when={!is_below_1160}>
    				<ClubsLogoWrapper>
    					{clubLogos.map(logo => (
    						<ClubWrapper
    							key={logo.name}>
    							<ClubLink
    								href={logo.link}
    								className=""
    								aria-label={logo.name}
    								target="_blank"
    								rel="noopener noreferrer">
    								<img src={logo.logo} alt={`${logo.name} team logo`} className="logo-expand"></img>
    							</ClubLink>
    							<ClubLogoInnerSpan className="name-span">
    								{logo.name}
    								<AddIconNew />
    							</ClubLogoInnerSpan>
    							<ScreenReaderSpan>{logo.name}</ScreenReaderSpan>
    						</ClubWrapper>
    					))}
    				</ClubsLogoWrapper>
    			</Exist>

    			<Exist when={is_below_1160}>
    				<ClubSitesMobileButton
    					onClick={this.toggleMobileClubsDropdown}
    					ref={this.mobileClubsButtonRef}>
                        Club Sites
    					<Triangle isOpen={mobileClubsDropdownOpen} />

    					<Exist when={mobileClubsDropdownOpen}>
    						<MobileClubsDropDown>
    							<MobileClubsWrapper>
    								{clubLogos.map(logo => (
    									<MobileClubWrapper
    										key={logo.name}>
    										<MobileClubLink
    											href={logo.link}
    											className=""
    											aria-label={logo.name}
    											title={logo.name}
    											target="_blank"
    											rel="noopener noreferrer">
    											<img src={logo.logo} alt={`${logo.name} team logo`} className="logo-color"></img>
    										</MobileClubLink>
    										{/* <MobileClubLogoInnerSpan className="name-span">
                                                {logo.name}
                                            </MobileClubLogoInnerSpan> */}
    										<ScreenReaderSpan>{logo.name}</ScreenReaderSpan>
    									</MobileClubWrapper>
    								))}
    							</MobileClubsWrapper>
    						</MobileClubsDropDown>
    					</Exist>
    				</ClubSitesMobileButton>
    				<StyledVerticalDivider />
    			</Exist>


    			<PlainButton
    				onClick={this.toggleTelstraDropdown}
    				ref={this.telstraButtonRef}>
    				<TelstraBlueImg className="telstra-blue" />
    				<TelstraWhiteOnlyImg className="telstra-white" />
    				<CreatedByTelstraText>
    					<p className="created-by-text">CREATED BY</p>
    					<p className="telstra-text">TELSTRA</p>
    				</CreatedByTelstraText>
    				<Triangle isOpen={telstraDropdownOpen} />

    				<Exist when={telstraDropdownOpen}>
    					<TelstraDropdown>
    						<InnewDropdown>
    							<TelstraWhiteImg />
    							<p className="subtext-1">
                                    More from Telstra
    							</p>
    							<p className="subtext-2">
                                    Australia's best network
    							</p>
    						</InnewDropdown>
    						<InnerLowerDropdown>
    							{TELSTRA_LINKS.map(link => (
    								<li key={link.title}>
    									<a href={link.link} target="_blank" rel="noopener noreferrer">
    										{link.title}
    										<AddIconNew />
    									</a>
    								</li>
    							))}
    						</InnerLowerDropdown>
    					</TelstraDropdown>
    				</Exist>
    			</PlainButton>
    		</HeaderWrapper>

    	);
    }
}

export default AFLHeader;