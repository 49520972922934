// @flow
import get from "lodash/get";

export const selectedDraftLeagueSelector = (state: Object) =>
	get(state, `leagues.by_id[${state.uiSelectedLeague.selected}]`, {});

export const selectedClassicLeagueSelector = (state: Object) =>
	get(state, `leaguesClassic.by_id[${state.uiSelectedLeague.selected_classic}]`, {});

export const isDraftSelector = (state: Object) =>
	state.uiMenu.pathname.substr(0, 6) === "/draft";

export const isClassicSelector = (state: Object) =>
	state.uiMenu.pathname.substr(0, 8) === "/classic";

export const isAccountSelector = (state: Object) =>
	state.uiMenu.pathname.substr(0, 8) === "/account";

export const pathnameSelector = (state: Object) => state.uiMenu.pathname;