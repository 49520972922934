// @flow

import "./utils/polyfills";
import "url-search-params-polyfill";
import "unorm";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { createGlobalStyle } from "styled-components";
import "./utils/GTM";

import { PersistGate } from "redux-persist/integration/react";

import { Event, EventHint, init, Integrations } from "@sentry/react";
import { unregister } from "./registerServiceWorker";
import styles from "./assets/css/index";
import App from "./pages/App";
import store from "./store";

const GlobalStyle = createGlobalStyle`${styles}`;

const root = document.getElementById("root");

const persistor = persistStore(store);

let errorCount = 0;
const MAX_ERRORS = 100;

init({
	dsn: "https://a46acbf5dd1f48aebf01d0f9d183a037@o151969.ingest.sentry.io/1390973",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	release: "tds-afl-fantasy@" + process.env.REACT_APP_PACKAGE_VERSION,
	tracesSampleRate: 0.01,
	whitelistUrls: [
		"fantasy.afl.com",
		".fanhubmedia.com",
	],
	blacklistUrls: [
		"bphf/res/js",
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
		"ajax.googleapis.com",
		".googlesyndication.com"
	],
	ignoreErrors: [
		"fb_xd_fragment", 
		/^_satellite.logger is undefined$/, 
		/^Illegal invocation$/, 
		/^Permission denied$/,
		/^SecurityError: The operation is insecure.$/,
		/^can't redefine non-configurable property "userAgent"$/,
		/^Unable to get property 'debug' of undefined or null reference$/,
		/^'s' is undefined$/,
		"Non-Error promise rejection captured",
		/^Object doesn't support property or method 'forEach'$/,
		"TypeError ?(src/instrument)",
		/^instrument$/,
		"In order to continue playing you will need to re-accept the new T&Cs",
		"Failed to fetch",
		"Can't find variable: _AutofillCallbackHandler",
		"A~thorization is required",
		/Can't find variable: _AutofillCallbackHandler/,
		/^Objects are not valid as a React child$/,
		/Load Failed/,
		"Load Failed",
		"TypeError: Load Failed",
		"Load",
		"Network Error",
		"Cannot redefine property: websredir",
		"Unexpected token '<'",
		"Failed",
		"Cannot read properties of null (reading 'postMessage')",
		"You have already joined this league",
		"You did not specify the type of Source, Token, or PaymentMethod to create. We could not infer which Element you want to use for this operation.",
		"NetworkError when attempting to fetch resource.",
		/Uncaught TypeError: 'get' on proxy: property 'javaEnabled' is a read-only/
	],
	ignoreUrls: [
		"/telstra-furniture/resources/ver/scripts/telstra-bar.min.js"
	],
	integrations: [
		new Integrations.Breadcrumbs({
			console: false,
		}),
	],
	autoSessionTracking: true,
	beforeSend: (event: Event, hint: EventHint) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

if (root) {
	root.click();
	render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<GlobalStyle />
				<App />
			</PersistGate>
		</Provider>,
		root,
	);
}

unregister();