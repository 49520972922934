// @flow
import _, { isEmpty } from "lodash";
import { createSelector } from "reselect";
import type { 
	TMatchesById, 
	TRootStore, 
	TRound, TRoundWithMatches, TRoundsById, TRoundsReducer } from "../../types";
import { getLeagueRounds } from "../../../helpers/league";
import leaguesDraftSelectors from "../leaguesDraft";
import {
	getLastCompleteRound as getLastCompleteRoundHelper,
	getActiveOrLastCompleteOrNextScheduledRound as getActiveOrLastCompleteOrNextScheduledRoundHelper
} from "../../../helpers/rounds";

export const getRounds = ({
	rounds: { ordered_ids, by_id }
}: TRootStore) => ordered_ids.map(round_id => by_id[round_id]);

export const getRoundsIsPending = ({rounds}: TRootStore) => {
	return rounds.is_pending && isEmpty(rounds.by_id);
};

export const getRoundsById = ({ rounds: { by_id } }: TRootStore) => by_id;

export const getMatchesById = ({ rounds }: TRootStore) => rounds.matches_by_id;

export const getRoundsWithMatches = createSelector(
	getRounds,
	getMatchesById,
	(rounds: Array<TRound>, matches_by_id: TMatchesById) => rounds.map(round => ({
		...round,
		matches: round.matches.map(match_id => matches_by_id[match_id])
	}))
);

export const getActualRound = createSelector(
	getRoundsWithMatches,
	(rounds: Array<TRound>) => _.isEmpty(rounds) ? {} : (
		_.find(rounds, ({ status, id }) => ["active", "scheduled"].includes(status) && id > 0)
		|| _.last(rounds)
	)
);

export const getActualRoundForCompetitionField = createSelector(
	getRoundsWithMatches,
	(rounds: Array<TRound>) => _.isEmpty(rounds) ? {} : (
		_.find(rounds, ({ status, id }) => ["active", "scheduled"].includes(status))
		|| _.last(rounds)
	)
);

export const getNextRound = createSelector(
	getRoundsWithMatches,
	getActualRound,
	(rounds: Array<TRound>, actual_round: TRound) => _.isEmpty(rounds) ? {} : (
		_.find(rounds, ({ id }) => _.eq(id, actual_round.id + 1)) || _.last(rounds)
	)
);

export const getFirstScheduledRound = createSelector(
	getRoundsWithMatches,
	(rounds: Array<TRound>) => _.isEmpty(rounds) ? {} : (
		_.find(rounds, ({ status }) => _.eq(status, "scheduled")) || _.last(rounds)
	)
);

export const getLastRound = createSelector(
	getRoundsWithMatches,
	(rounds: Array<TRound>) => _.isEmpty(rounds) ? {} :(
		_.findLast(rounds, ({ status }) => ["active", "complete"].includes(status))
		|| _.first(rounds)
	)
);

export const getByeRounds = createSelector(
	getRoundsWithMatches,
	(rounds: Array<TRound>) => _.isEmpty(rounds) ? [] : rounds.filter(_.property("is_bye"))
);

export const getFutureByeRounds = createSelector(
	getByeRounds,
	(rounds: Array<TRound>) => _.isEmpty(rounds) ? [] : rounds.filter(
		({ status }) => status === "scheduled"
	)
);

const getRoundId = (state: TRootStore, round_id: number) => round_id;

export const getSelectedRound = createSelector(
	getRoundsWithMatches,
	getRoundId,
	(rounds: Array<TRound>, round_id) => {
		if (_.isEmpty(rounds)) {
			return {};
		}
		if (round_id) {
			return _.find(rounds, ({ id }) => id === round_id);
		}
		return _.find(rounds, ({ status }) => ["active", "scheduled"].includes(status))
			|| _.last(rounds);
	}
);

export const isActiveMatch = createSelector(
	getSelectedRound,
	(round: TRoundWithMatches) => {
		const { matches } = round;

		return _.find(matches, ({ status }) => status === "playing");
	}
);

export const futureRoundsDisabled = createSelector(
	getRounds,
	leaguesDraftSelectors.getLeague,
	(rounds, league) => {
		return getLeagueRounds(rounds, league);
	}
);

export const isSeasonComplete = createSelector(
	getRounds,
	rounds => !_.isEmpty(rounds) ? _.last(rounds).status === "complete" : false
);



type TStore = {
	rounds: TRoundsReducer
}



export const hasRounds = (state: TStore): boolean => {
	return _.isEmpty(state.rounds.ordered_ids) === false || state.rounds.is_pending;
} ;




export const getLastCompleteRound = createSelector(
	getRounds,
	getLastCompleteRoundHelper
);

export const getActiveOrLastCompleteOrNextScheduledRound = createSelector(
	getRounds,
	(rounds: Array<TRound>) =>
		_.isEmpty(rounds) ? {} : getActiveOrLastCompleteOrNextScheduledRoundHelper(rounds)
);


export const getSelectedOrActualRound = (state: TStore, props: Object) => {
	const round_id = _.get(props, "match.params.round_id");
	return getSelectedRound(state, round_id) || getActualRound(state);
};
export const getSelectedOrActualRoundId = createSelector(
	getSelectedOrActualRound,
	(round: TRound) => round.id
);

export const getRoundById = createSelector(
	getRoundsById,
	getRoundId,
	(rounds: TRoundsById, round_id: number) => rounds[round_id]
);

export const isPreseason = createSelector(
	getRoundsById,
	(rounds: TRoundsById) => {
		if (_.isEmpty(rounds)) {
			return false;
		}
		return rounds[1].status === "scheduled";
	}
);