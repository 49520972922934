// @flow
import React from "react";
import copy from "copy-to-clipboard";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import {SEASON_YEAR} from "../../../modules/constants";
import * as selectors from "../../../modules/selectors";
import type { ById, TCelebTeam, TPLeague, TClassicTeam } from "../../../modules/types";
import colors from "../../../assets/css/colors";
import Plus from "../../../components/Icons/Plus";
import Delist from "../../../components/Icons/Delist";
import BorderedIcon from "../../../components/BorderedIcon";
import facebookSdk from "../../../utils/facebookSdk";

import {
	Ad,
	AdsContainer,
	ButtonSecondaryLink,
	Footer,
	LeagueGameBar,
	PageContentWrapper,
	PageTitle,
	Preloader,
	StandardSidebar,
	TickMessage,
	TwoColumnLayout,
} from "../../../components";

import Facebook from "../../../components/Icons/SocialFacebook";
import Twitter from "../../../components/Icons/SocialTwitter";
import SocialIcon from "../../../components/Icons/Link";
import { InvitePanel } from "../../draft/league/LeagueInvite";
import LeagueInviteEmail from "../../draft/league/leagueInvite/LeagueInviteEmail";

import { leagueValidate } from "../../../utils/FormValidations";

import InviteFormField from "./invite/formField";
import LeaguePin from "./invite/leaguePin";
import ButtonSocial from "./invite/buttonSocial";
import EmailFields from "./invite/emailFields";
import CelebrityInvites from "./invite/celebrityInvites";

const fieldLevelRequired = value => (value || typeof value === "number" ? undefined : "Required");
const fieldLevelEmail = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "Invalid email address"
		: undefined;

const SkipButtonWrapper = styled.div`
	display: flex;
`;

const AddButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const InviteTypeLabel = styled.div`
	font-size: 21px;
	font-weight: bold;
	line-height: 1.46;
	color: ${colors.primary.primary};
	padding: 15px 0 0;

`;

const BorderedIconStyled = styled(BorderedIcon)`
	height: 42px;
	margin: 24px 0 0 1em;
	border: none;
	border-radius: 0;
	cursor:pointer;
`;

const InviteSection = styled.div`
	margin-bottom: 30px;
	h3 {
		font-size: 18px;
		line-height: 18px;
		color: ${colors.primary.primary};
		margin: 1.666em 0 10px;
		&:first-child {
			margin-top: 0;
		}
	}
`;

const InviteTextWrap = styled.div`
	margin-bottom: 10px;
`;

type Props = {
	showClassicLeague: typeof actions.showClassicLeague,
	inviteToClassicLeague: typeof actions.inviteToClassicLeague,
	match: {
		params: {
			league_id: number,
		}
	},
	league: TPLeague,
	submitting: Function,
	invite_to: {
		is_pending: boolean,
		result: {
			result: boolean
		}
	},
	user: {
		id: number,
	},
	league_show: {
		error: string,
		league_id: number,
		is_pending: boolean,
	},
	regenerate_show: {
		error: string,
		is_pending: boolean,
		by_id: Object
	},
	getCelebs: typeof actions.getCelebrities,
	fetchLadder: typeof actions.ladderClassicLeague,
	addCelebritiesToLeague: typeof actions.addCelebritiesToLeague,
	removeTeam: typeof actions.removeTeamFromLeague,
	classic_celebs: ById<TCelebTeam>,
	league_teams: ById<TClassicTeam>,
	new_league?: boolean,
	add_celebrities: boolean,
	remove_team: boolean,
	fetchRosters: Function
}

type State = {
	email_field: Array<number>,
	display_emails: number,
	disable_send: boolean,
	code_copied: boolean,
	link_copied: boolean,
	regen_invites: Array<Object>,
	league_teams: ById<TClassicTeam>
};

class LeagueInviteComponent extends React.Component<Props, State> {
	static defaultProps = {
		new_league: false
	};

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"handleEmailInviteSubmit",
			"toggleDisplayEmails",
			"removeEmails",
			"copyLink",
			"copyCode",
			"getShareUrl",
			"getShareLink",
			"shareToTwitter",
			"shareToFacebook",
			"addCelebrity",
			"removeFromLeague",
		]);
	}

	state = {
		display_emails: 1,
		code_copied: false,
		link_copied: false,
		disable_send: true,
		email_field: _.range(0, 1),
		regen_invites: [],
		league_teams: {}
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		facebookSdk();
		const {
			match: {
				params: {
					league_id,
				},
			},
			showClassicLeague,
			getCelebs,
			fetchLadder,
		} = this.props;

		showClassicLeague({ id: league_id });
		getCelebs();
		fetchLadder({ league_id });
		this.setState({ league_teams: this.props.league_teams });
		
	}
		

	componentDidUpdate(prev_props: Props) {
		this.checkLeaguePending(prev_props);
		this.checkCelebsPending(prev_props);
		if (prev_props.league !== this.props.league) {
			const { league, fetchRosters, match: { params: { league_id } } } = this.props;
			if (league && league.type === 'head_to_head') {
				fetchRosters({ league_id });
			}
		}

		if(prev_props.league_teams !== this.props.league_teams){
			this.setState({
				league_teams: this.props.league_teams
			});
		}
	}

	componentWillUnmount() {
		if(this.code_copied_interval) {
			clearTimeout(this.code_copied_interval);
		}

		if(this.link_copied_interval) {
			clearTimeout(this.link_copied_interval);
		}
	}

	checkLeaguePending(prev_props: Props) {
		const {
			regenerate_show,
			league_show,
			league,
		} = this.props;

		// when this league is complete fetching
		if(prev_props.league_show.is_pending && !league_show.is_pending) {
			// check if it's a regen league
			if(league.regenerated_from !== 0) {
				// if regen leauge data is available
				if(league.regenerated_from in regenerate_show.by_id) {
					// populate
					const prev_league = regenerate_show.by_id[league.regenerated_from];
					this.setState({
						regen_invites: prev_league.users,
						disable_send: false,
						display_emails: prev_league.users.length,
						email_field: _.range(0, prev_league.users.length)
					});
				}
			}
		}
	}

	checkCelebsPending(prev_props: Props) {
		const {
			add_celebrities,
			remove_team,
			fetchLadder,
			showClassicLeague,
			match: { params: { league_id } }
		} = this.props;

		const celeb_added = prev_props.add_celebrities && !add_celebrities;
		const celeb_removed = prev_props.remove_team && !remove_team;

		if (celeb_added || celeb_removed) {
			fetchLadder({ league_id });
			showClassicLeague({ id: league_id });
		}
	}

	code_copied_interval = null;
	link_copied_interval = null;

	getShareLink() {
		const { league } = this.props;
		const DRAFT_LEAGUE_SHARE_LINK = process.env.REACT_APP_DRAFT_LEAGUE_SHARE_LINK || "";
		return `${DRAFT_LEAGUE_SHARE_LINK}${league.code}`;
	};

	getShareUrl() {
		const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
		const { league, user } = this.props;

		// console.log({ user });

		const data_to_encode = {
			share_type: "league",
			league_id: league.id,
			league_name: league.name,
			team_id: league.team_id,
			user_id: user.id,
			tc: window.Date.now().toString().substr(-4),
			code: league.code
		};

		const encoded_data = btoa(encodeURIComponent(JSON.stringify(data_to_encode)));
		return `${SHARE_URL}classic_league_invite/${encoded_data}`;
	}

	shareToFacebook() {
		if (window.FB) {
			window.FB.ui({
				method: "share",
				display: "popup",
				href: this.getShareUrl()
			},
			_.identity
			);
		}
	}

	shareToTwitter() {
		const { league } = this.props;
		window.open(
			`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.getShareUrl())}&text=${encodeURIComponent(`I have entered my team to compete in the league ${league.name} on AFL Fantasy! Looking forward to season ${SEASON_YEAR}? Sign up and join me in this league now!`)}`, // eslint-disable-line
			"twitter-share-dialog",
			"width=626,height=436",
		);
	}


	handleEmailInviteSubmit(api_params) {
		const {
			inviteToClassicLeague, match: {
				params: {
					league_id,
				},
			},
		} = this.props;

		const invites = _.filter(
			api_params.invites,
			invite => _.every(
				[invite, _.get(invite, "email"), _.get(invite, "firstname")]
			)
		);

		inviteToClassicLeague({
			league_id: league_id,
			invites,
		});
	};

	copyCode() {
		const {
			league: { code }
		} = this.props;

		this.setState({ code_copied: true });
		copy(code);

		// reset coppied after 5secons
		this.code_copied_interval = setTimeout(() => {
			this.setState({ code_copied: false });
		}, 5000);
	}

	copyLink() {
		const {
			league: { code }
		} = this.props;

		const base_url = process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : "";
		const copy_url = `${base_url}classic/leagues/join/${code}`;

		this.setState({ link_copied: true });
		copy(copy_url);

		// reset coppied after 5secons
		this.link_copied_interval = setTimeout(() => {
			this.setState({ link_copied: false });
		}, 5000);
	}

	toggleDisplayEmails() {
		const { display_emails } = this.state;
		this.setState({ display_emails: display_emails + 1 });
		this.setState({ email_field: _.range(0, display_emails + 1) });
	}

	removeEmails() {
		const { display_emails } = this.state;
		this.setState({ display_emails: display_emails - 1 });
		this.setState({ email_field: _.range(0, display_emails - 1) });
	}

	addCelebrity(team_id: number) {
		const { addCelebritiesToLeague, league } = this.props;

		if (!_.isEmpty(league) && team_id) {
			addCelebritiesToLeague({
				league_id: league.id,
				teams: [ team_id ]
			});
			this.setState({
				league_teams: {
					...this.state.league_teams,
					[team_id]: { id: team_id }
				}
			});
		}
	}

	removeFromLeague(team_id: number) {
		const { removeTeam, league } = this.props;

		if (!_.isEmpty(league) && team_id) {
			removeTeam({
				league_id: league.id,
				team_id
			});
		}
	}

	get isClassic() {
		return window.location.pathname.substr(0, 8) === "/classic";
	}

	get email_fields() {
		const { email_field } = this.state;

		const email_fields = email_field.map(item => (
			<EmailFields key={item}>
				<Field
					required
					name={"invites[" + item + "][firstname]"}
					label="First name"
					type="text"
					component={InviteFormField}
					className='form-field'
					validate={fieldLevelRequired}
				/>
				<Field
					required
					label="Email"
					name={"invites[" + item + "][email]"}
					type="email"
					component={InviteFormField}
					className='form-field'
					validate={[fieldLevelRequired, fieldLevelEmail]}
				/>
				{item === 0 ? (
					<AddButtonWrapper>
						<div
							onClick={this.toggleDisplayEmails}
							onKeyPress={this.toggleDisplayEmails}
							role="button"
						>
							<BorderedIconStyled
								background='#ffffff'
								padding={"0.1em"}
								hover={colors.primary.accent}
							>
								<Plus size={2} color={colors.primary.primary}/>
							</BorderedIconStyled>
						</div>
					</AddButtonWrapper>
				) : (
					<AddButtonWrapper>
						<div
							onClick={this.removeEmails}
							onKeyPress={this.removeEmails}
							role="button"
						>
							<BorderedIconStyled
								background='#ffffff'
								padding={"0.1em"}
								hover={colors.primary.accent}
							>
								<Delist size={2} color={colors.primary.primary}/>
							</BorderedIconStyled>
						</div>
					</AddButtonWrapper>
				)}
			</EmailFields>
		));

		return (
			<div>
				<InviteTypeLabel>
					Invite via email:
				</InviteTypeLabel>
				{email_fields}
			</div>
		);
	}

	render() {
		const {
			league, new_league, classic_celebs
		} = this.props;
		const { code_copied, link_copied } = this.state;

		if(_.isEmpty(league)) {
			return <Preloader />;
		}

		return <div>
			<LeagueGameBar no_fetch={true}/>
			<AdsContainer>
				<Ad/>
			</AdsContainer>

			<PageContentWrapper>
				<PageTitle>
					Invite people to join {league.name}
				</PageTitle>
				<TwoColumnLayout>
					<InvitePanel>
						<InviteSection>
							<h3>
								{`Great, now that you have created a league,
								invite others to join.`}
							</h3>
							<InviteTextWrap>
								<p>
									{league &&
								`The invitation PIN for ${league.name} is: ${league.code}`}
								</p>
							</InviteTextWrap>
							{code_copied ? <TickMessage>Code Copied</TickMessage> : null}
							<Field
								label='League Pin'
								type='text'
								name='league_pin'
								placeholder='League Pin'
								handleCopy={this.copyCode}
								component={LeaguePin}
							/>
						</InviteSection>
						<InviteSection>
							<LeagueInviteEmail
								onSubmit={this.handleEmailInviteSubmit}
								status={this.props.invite_to}
								regen_invites={this.state.regen_invites}
							/>
						</InviteSection>
						<InviteSection>
							<InviteTypeLabel>
							Invite via Social:
							</InviteTypeLabel>
							<ButtonSocial onClick={this.shareToFacebook}>
								<div>
									<Facebook size="1.2" height={"1.5em"} width={"1.5em"}/>
								</div>
								<div className="text">Facebook</div>
							</ButtonSocial>

							<ButtonSocial onClick={this.shareToTwitter}>
								<div>
									<Twitter size="1.2" height={"1.5em"} width={"1.5em"}/>
								</div>
								<div className="text">X (Formerly Twitter)</div>
							</ButtonSocial>
							<ButtonSocial onClick={this.copyLink}>
								<div>
									<SocialIcon size="1.2" height={"2em"} width={"2em"}/>
								</div>
								<div className="text">Copy league link</div>
							</ButtonSocial>
							{link_copied ? <TickMessage>Link Copied</TickMessage> : null}
						</InviteSection>
						<CelebrityInvites
							celebrities={classic_celebs}
							league_teams={this.state.league_teams}
							addCelebrity={this.addCelebrity}
							removeFromLeague={this.removeFromLeague}
							league={league}
							user_id={this.props.user.id}
						/>
						{new_league && <SkipButtonWrapper>
							<ButtonSecondaryLink
								to={`/classic/league/${league.id}/about`}>
							Skip this for now
							</ButtonSecondaryLink>
						</SkipButtonWrapper>}
					</InvitePanel>
					<StandardSidebar />
				</TwoColumnLayout>
			</PageContentWrapper>
			<Footer />
		</div>;
	}
}

LeagueInviteComponent = reduxForm({
	form: "league_invite",
	validate: leagueValidate,
})(LeagueInviteComponent);

const mapStateToProps = (state, props) => ({
	user: selectors.getUser(state),
	league: selectors.leaguesClassic.show.getLeague(state, props),
	invite_to: state.leaguesClassic.invite_to,
	regenerate_show: state.leaguesClassic.regenerate_show,
	league_show: state.leaguesClassic.show,
	classic_celebs: state.leaguesClassic.celebrities.by_id,
	league_teams: state.leaguesClassic.rosters.teams_by_id,
	add_celebrities: state.leaguesClassic.add_celebrities.is_pending,
	remove_team: state.leaguesClassic.remove_team.is_pending
});

const mapDispatchToProps = {
	inviteToClassicLeague: actions.inviteToClassicLeague,
	showClassicLeague: actions.showClassicLeague,
	fetchRosters: actions.getClassicLeagueRosters,
	getCelebs: actions.getCelebrities,
	fetchLadder: actions.ladderClassicLeague,
	addCelebritiesToLeague: actions.addCelebritiesToLeague,
	removeTeam: actions.removeTeamFromLeague
};

export const ClassicLeaguesInvite = connect(
	mapStateToProps,
	mapDispatchToProps,
)(LeagueInviteComponent);

export default ClassicLeaguesInvite;