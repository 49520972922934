// @flow
import React from "react";
import * as _ from "lodash";
import {
	ListViewPlayerEmpty,
	ListViewPlayer
} from "../../../components";
import { getPartialByeRound } from "../../../helpers";
import {
	shouldDoubleScore
} from "../../../helpers/stats/displayRound";
import {
	TPlayersById,
	TClassicTeam,
	TRound,
	TPlayer
} from "../../../modules/types";
import {
	getPointsLabel,
	getPointsLastRound,
	isEitherTrue,
	isBothTrue
} from "./helpers";



type Props = {
    params: any,
    index: number,
    players_by_id: TPlayersById,
    has_assistant_coach: boolean,
    is_team_started: boolean,
	is_partial_or_standard: string,
    team: TClassicTeam,
    selected_round: TRound,
    is_first_round_with_partial_lockout: boolean,
    is_static: boolean,
    is_current: boolean,
    bye_detector_active: boolean,
    bye_detector_selected: number[],
    actual_round: TRound,
    is_mobile: boolean,
    substitute_id: number,
    substitute_position: number,
    substitute_player_positions: number[],
    mobile_stat: string,
    substitute_is_utility: boolean,
    index: number,
	removePlayer: Function,
	inSubstitute: Function,
	swapWithEmptyPlayer: Function,
	pureSwapWithEmptyPlayer: Function,
	changePositionFilter: Function,
	onShowPlayerPool: Function,
	checkIsPlayerChangeAvailable: Function,
	removePlayer: Function,
	setCaptain: Function,
	setViceCaptain: Function,
	setEmergency: Function,
	substitutePlayer: Function,
	game_bar_stats_value: string,
	pureSubstitutePlayer: Function,
	cancelSubstitute: Function,
	openPopup: Function,
	locked_personnel: string[],
	player: TPlayer,
	rounds: TRound[],
	last_round: TRound,
}

export const MyTeamListViewPlayer = (props: Props)  => {

	const {
		players_by_id,
		has_assistant_coach,
		is_team_started,
		team: { lineup = {} },
		selected_round,
		is_first_round_with_partial_lockout,
		is_static,
		is_current,
		bye_detector_active,
		bye_detector_selected,
		actual_round,
		params,
		index,
		is_mobile,
		substitute_id,
		last_round,
		substitute_position,
		substitute_player_positions,
		mobile_stat,
		substitute_is_utility,
		inSubstitute,
		swapWithEmptyPlayer,
		pureSwapWithEmptyPlayer,
		changePositionFilter,
		onShowPlayerPool,
		checkIsPlayerChangeAvailable,
		removePlayer,
		setCaptain,
		setViceCaptain,
		setEmergency,
		substitutePlayer,
		pureSubstitutePlayer,
		cancelSubstitute,
		openPopup,
		game_bar_stats_value,
		rounds,
		is_partial_or_standard,
		locked_personnel,
		player,
	} = props;

	const {
		player_id,
		id,
		name,
		is_bench,
		captain,
		vice_captain,
		emergency,
		is_utility,
		utility_position
	} = params;

	const is_subs = Boolean(substitute_id);
	const out_position = substitute_player_positions.includes(id);

	if (isEitherTrue(!player_id, _.isEmpty(players_by_id))) {

		const in_substitute = inSubstitute(is_subs, false, true, out_position);
		return (
			<ListViewPlayerEmpty
				key={`empty-${index}`}
				position_id={id}
				position_full_name={name}
				is_bench={is_bench}
				in_substitute={Boolean(in_substitute)}
				substitutePlayer={swapWithEmptyPlayer}
				pureSubstitutePlayer={pureSwapWithEmptyPlayer}
				is_utility={is_utility}
				index={index}
				is_substitute={is_subs}
				is_classic={true}
				onClick={() => {
					changePositionFilter({ id, is_utility });

					if(is_mobile) {
						onShowPlayerPool();
					}
				}}
			/>
		);
	}

	const getRoundForScore = () => {
		const isPointsLastRound = game_bar_stats_value === "stats.points_last_round";
		return isPointsLastRound ? last_round : selected_round;
	};

	const is_emergency = emergency.includes(player_id);
	const is_substitute_case =
			isBothTrue(substitute_is_utility, substitute_player_positions.find(
				position =>
					player.positions.includes(position)
			));
	const out_substitute = isBothTrue(is_subs, substitute_id === player_id);
	const isPlayingNow = _.get(player, "is_playing_now", false);
	const isMatchScheduled = _.get(player, "is_match_scheduled", false);
		
	const is_locked = !checkIsPlayerChangeAvailable(player_id);
	const in_position = player.positions.includes(substitute_position) || is_substitute_case;
	const in_substitute = inSubstitute(is_subs, is_locked, in_position, out_position);
	const roundForPoints = getRoundForScore();
	const double_score = shouldDoubleScore(
		player,
		lineup,
		roundForPoints,
		players_by_id[captain]
	);

	

	const handleDefaultValue = () => {
		const round_id_use = selected_round.id - 1;
		if(round_id_use === 0){
			return "--";
		}
		return player.stats.bye_round_id === round_id_use ? "BYE" : "DNP";
	};

	const handleDoubleScore = (score: number) => {
		if(!_.isNumber(score) ){
			return handleDefaultValue();
		}
		return double_score ? 2*score : score;
	};

	const handleScore = () => {
		if(game_bar_stats_value ===	"stats.avg_points" ){
			return _.get(player, game_bar_stats_value).toFixed();
		}
		if("stats.proj_score" === game_bar_stats_value){
			return handleDoubleScore(_.get(player, game_bar_stats_value));
		}
		if(game_bar_stats_value === "stats.points_last_round"){
			const round_id_use = selected_round.id - 1;
			
			const handle_default = handleDefaultValue();
			
			return handleDoubleScore(_.get(player, `stats.scores.${round_id_use}`, handle_default));
		}
		if(game_bar_stats_value === "stats.round_score" && !isMatchScheduled){
			return handleDoubleScore(_.get(player, `stats.scores.${selected_round.id}`, "DNP"));
		}
		 
		return getPointsLastRound(pointsLabel, player, double_score, selected_round, actual_round);
	};

	const handleLabel = () => {
		if(game_bar_stats_value === "stats.avg_points"){
			return "average";
		}
		if(game_bar_stats_value === "stats.proj_score"){
			return "projected";
		}
		if(game_bar_stats_value === "stats.points_last_round"){
			return "last round";
		}
		return getPointsLabel(isPlayingNow, isMatchScheduled, has_assistant_coach);

	};
	const selectedRoundId = _.get(selected_round, 'id');
	const actualRoundId = _.get(actual_round, 'id');
	const pointsLabel = selectedRoundId < actualRoundId ? "completed" : 
		handleLabel();
	
	const pointsToUse = player ? 
		handleScore()
		: "-";
	const pointsLastRound = pointsToUse;
	const player_bye = _.get(player, "stats.bye_round_id", 0);
	const playerSquad = _.get(player, "squad_id",0);
	const player_partial_bye = getPartialByeRound(playerSquad, rounds);
	

	return (
		<ListViewPlayer
			key={player_id}
			player={player}
			position_id={id}
			is_locked={is_locked || is_static}
			is_captain={captain === player_id}
			is_team_started={is_team_started}
			is_vice={vice_captain === player_id}
			is_bench={is_bench}
			removePlayer={removePlayer}
			setCaptain={setCaptain}
			setViceCaptain={setViceCaptain}
			setEmergency={setEmergency}
			is_substitute={is_subs}
			out_substitute={out_substitute}
			in_substitute={Boolean(in_substitute)}
			substitutePlayer={substitutePlayer}
			pureSubstitutePlayer={pureSubstitutePlayer}
			cancelSubstitute={cancelSubstitute}
			is_emergency={is_emergency}
			has_assistant_coach={has_assistant_coach}
			openPlayerPopUp={openPopup}
			is_mobile={is_mobile}
			player_partial_bye={player_partial_bye}
			is_partial_or_standard={is_partial_or_standard}
			player_stat_field={game_bar_stats_value}
			double_score={double_score}
			is_first_round_with_partial_lockout={is_first_round_with_partial_lockout}
			selected_round={selected_round}
			is_static={is_static}
			is_current={is_current}
			player_bye={player_bye}
			bye_detector_active={bye_detector_active}
			bye_detector_selected={bye_detector_selected}
			mobile_stat={mobile_stat}
			locked_personnel={locked_personnel}
			is_utility={is_utility}
			utility_position={utility_position}
			actual_round={actual_round}
			pointsLastRound={pointsLastRound}
			pointsLabel={pointsLabel}
			is_my_team={true}
		/>
	);
	
};