// @flow
import React from "react";
import styled from "styled-components";
import { IS_TERMS_CLOSED } from "../../modules/constants";
import colors from "../../assets/css/colors";

const ContentDiv = styled.div`
	p{
		font-family: SourceSansPro;
		font-size: 14px;
		color: ${colors.primary.primary};
		margin: 20px 0
	}
	a{
		color: ${colors.primary.primary};
	}
`;


const TermsConditions = () => {

	return (
		<ContentDiv>
			{IS_TERMS_CLOSED ?
				<p>Full Terms & Conditions will be available soon.</p>
				:
				<p>Full Terms & Conditions available here: <a href="/terms/AFL_Fantasy_2025_Terms_Conditions.pdf" target="_blank" rel="noreferrer noopener">Download PDF</a>.</p>
			}
			<p>
				{IS_TERMS_CLOSED ?
					'In the meantime, you'
					: 'You'
				} can access the AFL’s privacy policy at {" "}
				<a href="https://www.afl.com.au/privacy"
					target="_blank" rel="noreferrer noopener">
					https://www.afl.com.au/privacy</a>
				{" "}, Telstra’s privacy policy at {" "}
				<a href="https://www.telstra.com.au/privacy"
					target="_blank" rel="noreferrer noopener">
					https://www.telstra.com.au/privacy</a>
				and Toyota's privacy policy at {" "}
				<a href="https://www.toyota.com.au/privacy-policy "
					target="_blank" rel="noreferrer noopener">
					https://www.toyota.com.au/privacy-policy
				</a>
			</p>

			<p>You can request the deletion of your personal
				information held by the AFL by emailing {" "}
			<a href="mailto:privacy.officer@afl.com.au"
				target="_blank" rel="noreferrer noopener">
					privacy.officer@afl.com.au</a>,
				or by writing to the AFL via AFL Privacy Officer,
				AFL House, 140 Harbour Esplanade, Docklands, Victoria 3008,
				or by telephoning 03 9643 1999.</p>

			<p>You can request the deletion
				of your personal information held by Telstra by telephoning
				1800 039 059, or by emailing {" "}
			<a href="mailto:privacy@online.telstra.com.au"
				target="_blank" rel="noreferrer noopener">
					privacy@online.telstra.com.au</a>.</p>

		</ContentDiv>
	);
};

export default TermsConditions;