import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import Lock from "../Icons/Lock";
import { PARTIAL_BYE_ROUNDS } from "../../helpers";
import colors from "../../assets/css/colors";
import { currencyFormat, isMobile } from "../../utils";
import type { TRound } from "../../modules/types";
import {
	CLASSIC_STATS_OVERVIEW,
	DRAFT_STATS_OVERVIEW
} from "./StatFields";
import {
	SectionHeader,
	StatHeadItem,
	StatHeadRow,
	StatItem,
	StatItemRow,
	// StatBubbleRow
} from "./PlayerPopupStyles";



const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;


const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;

// const StatBubble = styled.div`
// 	width: 54px;
// 	height: 56px;
// 	background: #1D4073;
// 	border-radius: 40%;
// 	font-family: SourceSansPro;
// 	font-weight: 600;
// 	color: #fff;
// 	font-size: 10px;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	margin: 5px;
// 	justify-content: center;
// 	padding: 10px;
// 	p{
// 		max-width: 40px;
// 		text-align: center;
// 	}
// 	span{
// 		font-size: 26px;
// 		${({isPrice}) => isPrice && `
// 			font-size: 16px;
// 		`}
// 	}
// 	${({is_fc}) => is_fc && `
// 		color: ${colors.coach};
// 		span{
// 			color: ${colors.coach};
// 		}
// 	`}
// `;

const Price = styled.span`
	color: ${ props => props.positive ? colors.primary.accentGreen : "red"};
`;

export const priceDiff = number => {
	const is_positive=_.isNumber(number) && number >= 0;
	return <Price 
		positive={is_positive}>
		{currencyFormat({input: number, show_sign:true})}
	</Price>;
};

type KeyStatProps = {
    is_classic: boolean,
    league: boolean,
    rounds: TRound[],
    player: TPlayer,
    has_coach: boolean,
    actual_round: number,
    fullLink: string
}

export const KeyStatsComponent = (props: KeyStatProps ) => {
	const isLeagueCustomScoring = Boolean(props.league) && props.league.custom_scoring_enabled;
	const statsBasis = isLeagueCustomScoring ? "custom_stats" : "stats";
	const statsToUse = 
        props.is_classic 
        	? CLASSIC_STATS_OVERVIEW(props.actual_round - 1) 
        	: DRAFT_STATS_OVERVIEW(statsBasis);
	const player_bye_round = _.find([...props.rounds].reverse(),
		round => _.includes(round.bye_squads, props.player.squad_id) 
				&& !PARTIAL_BYE_ROUNDS.includes(round.id) 
	) || { id: 0 };
		
	// const printStat = stat => {
	// 	const statValue = _.get(props.player, stat.prop, 0);
	// 	if(stat.prop.includes('cost')){
	// 		return numeral(statValue).format(format.SHORT_PRICE);
	// 	}
	// 	return statValue;
	// };
	return(
		<FlexCol>
			<SectionHeader>
				<p>Player Overview</p>
				
			</SectionHeader>
			<StatHeadRow className=''>
				{statsToUse.map(stat => {
					return(
						<StatHeadItem key={`-${stat.title}`} is_fc={stat.is_fc}>
							{isMobile() ? stat.short_title : stat.title}
						</StatHeadItem>
					);
				})}
			</StatHeadRow>
			<StatItemRow className=''>
				{statsToUse.map((stat, index) => {
					const statValue = _.get(props.player, stat.prop, 0);
					if(stat.title === "Bye Round"){
						return(
							<StatItem key={`${index}-key-stat-hidden-mob`} is_fc={true}>
								{!props.has_coach ? 
									(<FcLink href={"/coach-subscription"} target="_blank">
										<Lock color={colors.coach} />
									</FcLink>):	player_bye_round.id}
							</StatItem>
						);
					}
					if(["stats.cost_round_diff", "stats.cost_season_diff"].includes(stat.prop)){
						return(
							<StatItem key={`${index}-key-stat-hidden-mob`}>
								{priceDiff(statValue)}
							</StatItem>
						);
					}
					return(
						<StatItem key={`${index}-key-stat-hidden-mob`}>
							{_.get(props.player, stat.prop, 0)}
						</StatItem>
					);
				})}
			</StatItemRow>
			
		</FlexCol>
	);
};