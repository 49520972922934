// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";
import { arrayToObjectById } from "./../../../helpers";

export const initial_state = {
	is_pending: false,
	error: null,
	by_id: {},
	ordered_ids: [],
	matches_by_id: {},
	past_years: {

	}
};

export const rounds = createReducer({}, initial_state);

rounds.on(actions.fetchRounds, state => ({
	...state,
	is_pending: true,
}));

rounds.on(actions.fetchRoundsFailed, (state, payload) => ({
	...state,
	is_pending: false,
	error: payload,
}));

rounds.on(actions.fetchRoundsSuccess, (state, payload: any) => {
	// build by id
	const by_id = payload.reduce((accumulator, currentValue) => ({
		...accumulator,
		[currentValue.id]: {
			...currentValue,
			matches: currentValue.matches.map(item => item.id),
		},
	}), {});

	// build matches by id
	const matches_by_id = payload.reduce((accumulator, currentValue) =>
		arrayToObjectById(currentValue.matches, accumulator), {});

	return {
		...state,
		is_pending: false,
		error: null,
		by_id,
		ordered_ids: payload.map(item => item.id),
		matches_by_id,
	};
});

rounds.on(actions.fetchPastRounds, state => ({
	...state,
	is_pending: true,
}));

rounds.on(actions.fetchPastRoundsFailed, (state, payload) => ({
	...state,
	is_pending: false,
	error: payload
}));

rounds.on(actions.fetchPastRoundsSuccess, (state, payload: any) => {
	const data = payload.result;
	const by_id = data.reduce((accumulator, currentValue) => ({
		...accumulator,
		[currentValue.id]: {
			...currentValue,
			matches: currentValue.matches.map(item => item.id),
		},
	}), {});

	// build matches by id
	const matches_by_id = data.reduce((accumulator, currentValue) =>
		arrayToObjectById(currentValue.matches, accumulator), {});
	return {
		...state,
		is_pending: false,
		error: null,
		past_years: {
			...state.past_years,
			[payload.year]: {
				by_id,
				ordered_ids: data.map(item => item.id),
				matches_by_id,
			}
			
		}
	};
});

export default rounds;