// @flow
import styled, { css } from "styled-components";
import FieldBG from "../../assets/images/field-bg.jpg";
// import FieldBGLarge from "../../assets/images/field-bg-large.jpg";
// import FieldBGRetina from "../../assets/images/field-bg-org.jpg";
import { below } from "../../assets/css/media";

// const getBackgroundOpacity = ({ is_substitute, is_locked }) => {
// 	if (is_substitute) {
// 		return '0.8';
// 	}
// 	if (is_locked) {
// 		return '0.6';
// 	}
// 	return '0';
// };

export const FieldBackground = styled.div`
	background-image: url(${FieldBG});
	background-size: 150%;
	background-position: -230px -275px;
	background-repeat: no-repeat;
    background-color: rgb(29, 25, 23);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;

	border-radius: 10px;

	// ${below.desktop`
	// 	background-image: url(${FieldBG});
	// `};

	// @media screen and (min-width: 1440px) {
	// 	background-image: url(${FieldBG}), url(${FieldBG});
	// }

	transition: filter 0.6s linear;

	${({ is_locked }) => is_locked && css`
		filter: grayscale(100%);
	`}
`;

export const Field = styled.div`
    background-color: rgb(29, 25, 23);
	position: relative;

	border-radius: 10px;
	margin-top: 10px;

	// &:before {
	// 	content: '';
	// 	background: #262424;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	bottom: 0;
	// 	right: 0;
	// 	z-index: 2;
	//
	// 	transition: opacity 0.6s linear;
	//
	//
	// 	border-radius: 10px;
	// }

	${({ is_draft }) => is_draft ? css`
		padding: 10px;
	` : css`
		//&:after {
		//	content: '';
		//	background: rgb(29, 25, 23);
		//	box-shadow: 0 0 20px 0 rgba(127,138,144,0.50);
		//	position: absolute;
		//	height: 100%;
		//	width: 140px;
		//	top: 0;
		//	right: 0;
		//	z-index: 1;
        //
		//	border-radius: 0 10px 10px 0;
		//}
	`}
`;

export default Field;