// @flow
import * as React from "react";
import * as _ from "lodash";
import connect from "react-redux/es/connect/connect";
import styled from "styled-components";
import numeral from "numeral";
import type { TPlayer, TRound, TPLeague, TLadderTeam } from "../../modules/types";
import { TableFlexBody, TableFlexCell, TableFlexRow, TableGrid } from "../Table";
import colors from "../../assets/css/colors";
import * as selectors from "../../modules/selectors";
import FantasyCoachModal from "../Modal/FantasyCoachModal";
import Lock from "../Icons/Lock";
import * as format from "../../helpers/numeralFormats";
import { below } from "../../assets/css/media";
import type { TRootStore } from "../../modules/types";
import { currencyFormat, isMobile, isOffSeason } from "../../utils";
import { getIsRegenerated, isAllTrue } from "../../helpers";
import type { TStat } from "./printStat";
import { Price, priceDiff } from "./printStat";

const ROUND_TEMP = "<%X%>";

const SEASON_YEAR = process.env.REACT_APP_SEASON_YEAR || "";

const NULL_PRICE = "- -";

numeral.nullFormat(NULL_PRICE);


const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
	margin: 9.5px 0;
`;

const PRICE_DIFF_STATS = [
	"stats.cost_round_diff",
	"stats.cost_season_diff",
	"stats.proj_price_change_next_round"
];

const classicStats: Array<TStat> = [
	{
		name: "GAMES PLAYED",
		id: "stats.games_played"
	},
	{
		name: "LAST ROUND SCORE",
		id: "stats.points_last_round"
	},
	{
		name: "AVERAGE SCORE",
		id: "stats.avg_points"
	},
	{
		name: "PRICE",
		id: "cost",

	},

	{
		name: "TOTAL POINTS",
		id: "stats.total_points"
	},
	{
		name: "LAST 3 AVG",
		id: "stats.last_3_avg"
	},
	{
		name: "LAST 5 AVG",
		id: "stats.last_5_avg"
	},
	{
		name: `ROUND ${ROUND_TEMP} PRICE CHANGE`,
		id: "stats.cost_round_diff",
		format: format.SHORT_PRICE
	},

	{
		name: "% TOG",
		id: "stats.tog",
		format: format.PERCENTAGE
	},
	{
		name: "HIGHEST SEASON SCORE",
		id: "stats.high_score"
	},
	{
		name: "LOWEST SEASON SCORE",
		id: "stats.low_score"
	},
	{
		name: "SEASON PRICE CHANGE",
		id: "stats.cost_season_diff",
		format: format.SHORT_PRICE
	},

	{
		name: `ROUND ${ROUND_TEMP} RANK`,
		id: "stats.ranks.rd_rank"
	},
	{
		name: "SEASON RANK",
		id: "stats.season_rank",
	},
	{
		name: "% OWNERSHIP",
		id: "stats.owned_by",
		format: format.PERCENTAGE
	},
	{
		name: "$ / POINT",
		id: "stats.cost_divided_by_points",
		format: format.DEC_PRICE
	},

	{
		name: "PROJECTED SCORE",
		id: "stats.proj_score",
		is_coach: true
	},
	{
		name: "PROJECTED PRICE CHANGE",
		id: "stats.proj_price_change_next_round",
		is_coach: true,
		format: format.SHORT_PRICE
	},
	{
		name: "BREAK EVEN",
		id: "stats.break_even",
		is_coach: true
	},
	{
		name: "BYE ROUND",
		id: "stats.bye_round",
		is_coach: true
	},
];


const draftStats = (statsBasis: string = "stats") => {
	return [
		{
			name: "GAMES PLAYED",
			id: `${statsBasis}.games_played`
		},
		{
			name: "LAST ROUND SCORE",
			id: `${statsBasis}.points_last_round`
		},
		{
			name: "AVERAGE SCORE",
			id: `${statsBasis}.avg_points`
		},
		{
			name: "TOTAL POINTS",
			id: `${statsBasis}.total_points`
		},
		{
			name: "LAST 3 AVG",
			id: `${statsBasis}.last_3_avg`
		},
		{
			name: "LAST 5 AVG",
			id: `${statsBasis}.last_5_avg`
		},

		{
			name: "% TOG",
			id: "stats.tog",
			format: format.PERCENTAGE
		},
		{
			name: "HIGHEST SEASON SCORE",
			id: `${statsBasis}.high_score`
		},
		{
			name: "LOWEST SEASON SCORE",
			id: `${statsBasis}.low_score`
		},

		{
			name: `ROUND ${ROUND_TEMP} RANK`,
			id: "stats.ranks.rd_rank"
		},
		{
			name: "SEASON RANK",
			id: "stats.season_rank",
		},
		{
			name: "DRAFT POSITION",
			id: "draft_position",
		},
		{
			name: "ADP",
			id: "stats.adp",
			is_coach: false
		},

		{
			name: "% CAPTAIN",
			id: "stats.selections_info.c",
			is_coach: true
		},
		{
			name: "PROJECTED SCORE",
			id: statsBasis === "custom_stats" 
				? `${statsBasis}.proj_score_next_round` : `${statsBasis}.proj_score`,
			is_coach: true
		},
		{
			name: "BYE ROUND",
			id: "stats.bye_round",
			is_coach: true
		},
		{
			name: "IN",
			id: "stats.traded_in_week",
			is_coach: true
		},
		{
			name: "OUT",
			id: "stats.traded_out_week",
			is_coach: true
		},
	];
};

const OverviewWrapper = styled.div`
	margin-bottom: 20px;
`;

const StyledTable = styled(TableGrid)`
	border: none;
`;
const StyledBody = styled(TableFlexBody)`
`;
const StyledRow = styled(TableFlexRow)`
	border: none;
	position: relative;

	${below.desktop`
		justify-content: flex-start;
	`}
`;

const StyledCell = styled(TableFlexCell)`
	text-align: center;
	border: none;
	margin: 15px 0 10px 0;
	flex: 1 1 auto;
	flex-direction: column;
	width: 120px;
	height: 120px;

	:last-child {
		border: none;
	}

	${below.desktop`
		max-width: 20%;
		height: 60px;
	`}
`;

const ResponsiveRow = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	
`;

const StatHeader = styled.h3`
	font-size: 12px;
	min-height: 24px;
	text-align: center;
	color: ${props => props.is_coach ? colors.coach : colors.primary.primary };
	margin-bottom: 8px;
	${below.desktop`
		font-size: 10px;
		min-height: 20px;
	`}
`;
const StatScore = styled.div`
	font-size: ${({ is_undrafted }) => is_undrafted ? "20px" : "40px"};
	line-height: 40px;
	font-family: SourceSansPro;
	font-weight: bold;
	color: ${props => props.is_coach ? colors.coach : colors.primary.primary };
	${below.desktop`
		font-size: 26px;
    line-height: 26px;
	`}
	${below.phone`
	font-size: 18px;
    line-height: 18px;
	`}
`;

type TPastYears = {[year: string]: TPlayer};

type Props = {
	player: TPlayer,
	is_classic?: boolean,
	has_assistant_coach: boolean,
	actual_round: TRound,
	changeYear: Function,
	by_year: string,
	past_years: TPastYears,
	rounds: {[number]: TRound},
	custom_scoring_enabled?: boolean,
  league: TPLeague,
	teams_by_id: {
		[teamID: number | string]: TLadderTeam
	},
};

type State = {
	show_fantasy_coach_modal: boolean,
	is_mobile: boolean
}

class PlayerOverviewComponent extends React.Component<Props, State> {
	constructor(props: Props, state: State) {
		super(props);

		this.bye_rounds = {};
		if (!_.isEmpty(props.rounds)) {
			_.forEach(props.rounds, r => {
				_.forEach(r.bye_squads, squad => {
					this.bye_rounds[squad] = r.id;
				});
			});
		}

		_.bindAll(this, [
			"makeStatsRow",
			"makeStatsCell",
			"getStatValue",
			"isPrice",
			"displayStat",
			"toggleFantasyCoachModal",
			"resize"
		]);
	}

	state = {
		show_fantasy_coach_modal: false,
		is_mobile: isMobile()
	};
	componentDidMount() {
		window.addEventListener("resize", this.resize);
		this.resize();

	}
	componentDidUpdate(old_props: Props) {
		const { rounds } = this.props;
		const old_rounds = old_props.rounds;

		if (_.isEmpty(old_rounds) && !_.isEmpty(rounds)) {
			_.forEach(rounds, r => {
				_.forEach(r.bye_squads, squad => {
					this.bye_rounds[squad] = r.id;
				});
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	get kept_players() {
		const { teams_by_id, league } = this.props;
		const { keeper, regenerated_from, uf_regenerated_from } = league;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const isKeeperandRegen = Boolean(keeper) && isRegenerated;
		if(!isKeeperandRegen){
			return [];
		}
		return Object.keys(teams_by_id).map(teamID => {
			const keeperPlayers = _.get(teams_by_id[teamID], "keepers", []);
			return keeperPlayers;
		}).flat().filter(playerID => playerID !== null);;

	}

	findDraftPosition(id: number) {
		const { 
			teams, 
			draft_history, 
			keeper, 
			regenerated_from,
			uf_regenerated_from
		} = this.props.league;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const is_keeper_league = Boolean(keeper) && isRegenerated;
		let pickNumber = 0;

		if(isAllTrue([this.props.league, this.props.league.draft_history])){
			draft_history.forEach((pick, index) => {
				teams.forEach(team => {
					// check value accessing 
					const value = _.get(pick, `${team}`, 0);
					if(value === id){
						pickNumber = index + 1;
					}
				});
			});
		}
		if(isAllTrue([pickNumber === 0, is_keeper_league, this.kept_players.includes(id)])){
			return "Keeper";
		}
		if( pickNumber === 0){
			return "Undrafted";
		}
		
		return pickNumber;
	}

	bye_rounds: {[squad_id: number]: number | string};

	makeStatsRow(stats: Array<TStat>, i: number) {
		return (
			<StyledRow key={i}>
				{_.map(stats, this.makeStatsCell)}
			</StyledRow>
		);
	}

	makeStatsCell(stat: TStat) {
		const { has_assistant_coach, actual_round } = this.props;
		const round_number = actual_round.id ? actual_round.id - 1 : "1";
		const statValue = this.displayStat(stat);
		const is_undrafted = statValue === "Undrafted" || statValue === "Keeper";
		const name = stat.name.includes(ROUND_TEMP)
			? stat.name.replace(ROUND_TEMP, _.toString(round_number))
			: stat.name;
		if(isAllTrue([isOffSeason(), stat.is_coach === true])){
			return null;
		}
		return (
			<StyledCell is_coach={stat.is_coach} key={stat.id}>
				<StatHeader is_coach={stat.is_coach}>{name}</StatHeader>
				{stat.is_coach && !has_assistant_coach ? (
					<FcLink onClick={this.toggleFantasyCoachModal}>
						<Lock color={colors.coach} size={1.8} />
					</FcLink>
				) : (
					<StatScore 
						is_coach={stat.is_coach} 
						is_undrafted={is_undrafted}
					>
						{statValue}
					</StatScore>
				)}
			</StyledCell>
		);
	}

	isPrice(stat: TStat) {
		return stat.id.includes("price") || stat.id.includes("cost");
	}

	getPriceDiffYears(past_years, by_year, player_id) {
		const p_start = _.get(past_years, by_year + "." + player_id + ".stats.prices.1", null);
		const p_end = _.get(past_years, by_year + "." + player_id + ".stats.prices.24", null);
		if (p_start !== null && p_end !== null) {
			return p_end - p_start;
		}
		return NULL_PRICE;
	}

	getStatValue(stat: TStat): string | number {
		const { player, past_years, by_year, actual_round } = this.props;
		if (!player) {
			return NULL_PRICE;
		}
		let s_id = stat.id;
		if (s_id === "stats.ranks.rd_rank") {
			s_id = `stats.ranks.${actual_round.id - 1}`;
		}
		if (s_id === "stats.bye_round") {
			return _.get(this.bye_rounds, `[${player.squad_id}]`, NULL_PRICE);
		}
		if (by_year === SEASON_YEAR) {
			return _.get(player, s_id, NULL_PRICE);
		}
		if (s_id === "stats.cost_season_diff") {
			return this.getPriceDiffYears(past_years, by_year, player.id);
		}
		if(s_id === "draft_position"){
			return this.findDraftPosition(player.id);
		}
		return _.get(past_years, by_year + "." + player.id + "." + s_id, NULL_PRICE);
	}

	displayStat(stat: TStat) {
		let raw_value = this.getStatValue(stat);
		const hasValue = raw_value !== NULL_PRICE;
		if(stat.id === "cost"){
			return currencyFormat({input: Number(raw_value)});
		}

		if (isAllTrue([
			!_.isString(raw_value),
			hasValue,
			typeof raw_value === "number"
		])) {
			if (stat.format === format.PERCENTAGE) {
				raw_value = Number(raw_value) / 100;
			}

			const num = numeral(raw_value);
			let formatted = num.format(stat.format ? stat.format : format.DEFAULT);

			if (_.indexOf(PRICE_DIFF_STATS, stat.id) !== -1) {
				return <Price positive={Number(raw_value) >= 0}>{priceDiff(Number(raw_value), 0)}
				</Price>;
			}

			return formatted;
		}

		return raw_value;
	}
	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}


	render() {
		const { is_classic, custom_scoring_enabled, by_year } = this.props;
		const { show_fantasy_coach_modal, is_mobile } = this.state;
		const draftChunkSize = is_mobile ? 5 : 9;
    
		const is_current_year = by_year === SEASON_YEAR;
		
		const statsBasis = custom_scoring_enabled && is_current_year ? "custom_stats" : "stats";

		return (
			<OverviewWrapper>
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}				
				<StyledTable>
					<StyledBody>
						{
							is_classic ? (
								<ResponsiveRow>
									{classicStats.map(this.makeStatsCell)}
								</ResponsiveRow>
							) : _(draftStats(statsBasis)).chunk(draftChunkSize)
								.map(this.makeStatsRow).value()
						}
					</StyledBody>
				</StyledTable>
			</OverviewWrapper>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => ({
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
	past_years: state.players.past_years,
	rounds: state.rounds.by_id,
	teams_by_id: selectors.getTeamsById(state),
});

export const PlayerOverview = connect(
	mapStateToProps
)(PlayerOverviewComponent);

export default PlayerOverview;