// @flow
import { sizes } from "../../assets/css/media";

export const isMobile = () => {
	return typeof window.matchMedia !== "undefined"
		? window.matchMedia(`(max-width: ${sizes.desktop - 1}px)`).matches
		: false;
};

export const isBelowTablet = () => {
	return typeof window.matchMedia !== "undefined"
		? window.matchMedia(`(max-width: ${sizes.tablet - 1}px)`).matches
		: false;
};

export const isBelow = (width: number) => {
	return typeof window.matchMedia !== "undefined"
	  ? window.matchMedia(`(max-width: ${width}px)`).matches
	  : false;
};

Object.defineProperties(isMobile, {
	Android: {
		value: () => Boolean(navigator.userAgent.match(/Android/i)),
		configurable: false,
	},
	BlackBerry: {
		value: () => Boolean(navigator.userAgent.match(/BlackBerry/i)),
		configurable: false,
	},
	iOS: {
		value: () => Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i)),
		configurable: false,
	},
	Opera: {
		value: () => Boolean(navigator.userAgent.match(/Opera Mini/i)),
		configurable: false,
	},
	Windows: {
		value: () => Boolean(
			navigator.userAgent.match(/IEMobile/i)
			|| navigator.userAgent.match(/WPDesktop/i)
		),
		configurable: false,
	},
	any: {
		value: () => Boolean(
			isMobile.Android()
			|| isMobile.BlackBerry()
			|| isMobile.iOS()
			|| isMobile.Opera()
			|| isMobile.Windows()
		),
		configurable: false,
	}
});

export default isMobile;