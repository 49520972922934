// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _, { isNaN } from "lodash";
import type { TPlayersArrayWithFixtureById, TRoundsWithMatches } from "../../../modules/types";
import colors from "../../../assets/css/colors";
import Caution from "../../../components/Icons/Caution";
import type { TPosition } from '../../../modules/types/position';
import type { TClassicTeam } from "../../../modules/types/teamClassic";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import {
	OpenTeamFieldButton,
	ListBenchPlayers,
	ListRow,
	ListRowLabel,
	ListViewHeader,
	SectionHeader,
	TeamGameBar,
	TeamInfoBlock,
	ModalContainer,
	ModalInnerTitle,
	ButtonSecondary, ButtonAlert,
} from "../../../components";
import { convertLineupForBE } from "../../../modules/sagas/teamsClassic";
import type { TRound } from "../../../modules/types/rounds";
import Arrow from "../../../components/Icons/Arrow";
import { isAllTrue, isEditTradePage } from "../../../helpers";
import {
	TeamInfoWrapper,
	TeamList,
	ModalInnerSubTitle,
	ConfirmButtonsWrapper,
	UserTeamInfo
} from "./userTeamStyles";
import { ListViewPlayersByPosition } from "./ListViewPlayersByPosition";
import {
	swapPlayersOnBench,
	setPlayerId
} from "./userTeamHelpers";
import {ShowTradeInPlayer} from "./ShowTradeInPlayer";




const ONE_DAY_MS = 86400000;
type Props = {
	team: TClassicTeam,
	positions: Array<TPosition>,
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	removePlayer: Function,
	errors: string | null,
	closeErrorModal: Function,
	actual_round: TRound,
	is_team_started: boolean,
	player_ids: Array<number>,
	selected_round: TRound,
	selected_round_id: number,
	data_loading_complete: boolean,
	rounds: Array<Object>,
	is_pending_players: boolean,
	remaining_salary: number,
	is_mobile: boolean,
	openPopup: Function,
	user_trades: Array<Object>,
	initial_lineup: Object,
	addPlayerOutTrade: typeof actions.addPlayerOutTrade,
	removePlayerOutTrade: typeof actions.removePlayerOutTrade,
	swapPlayersOutTrade: typeof actions.swapPlayersOutTrade,
	removePlayerInTrade: typeof actions.removePlayerInTrade,
	players_on_trade: Array<Object>,
	selected_trade_object: Object,
	player_id: number,
	onSwitchPlayerPool: Function,
	bye_detector_controls: React.Node,
	bye_detector_active: boolean,
	bye_detector_selected: number[],
	controls: React.Node,
	mobile_stat: Object,
	score_stat: Object,
	updatePlayerSwap: typeof actions.updatePlayerSwap,
	trade_id: number,
	player_last_removed?: number;
	addPlayerInTrade: typeof actions.addPlayerInTrade,
	initial_players_on_load: [],
	addPlayerInTradeAndUpdateTeam: typeof actions.addPlayerInTradeAndUpdateTeam,
	rounds_with_matches: Array<TRoundsWithMatches>,
}
type State = {
	substitute_id: number,
	substitute_position: number,
	substitute_player_positions: Array<number>,
	substitute_position_index: number,
	swap_trade_out_id: number,
	substitute_is_utility: boolean,
	substitute_is_bench: boolean,
	removed_player_params: Object,
	show_remove_player_modal: boolean
}
class UserTeamComponent extends React.Component<Props, State> {
	static defaultProps = {
		data_loading_complete: false,
		controls: null,
	};
	constructor() {
		super();

		_.bindAll(this, [
			"getListFieldRow",
			"getListViewPlayersByPosition",
			"checkIsPlayerChangeAvailable",
			"setPlayerOnTrade",
			"removePlayerOnTrade",
			"removePlayerInTrade",
			"substitutePlayer",
			"findLeader",
			"tryToSubstitute",
			"cancelSubstitute",
			"swapPlayersOnField",
			"swapEmergency",
			"findTradeByPlayerId",
			"inSubstitute",
			"handleConfirmRemovePlayerModal",
			"undoTrade",
			"showTradeInPlayer"
		]);
	}

	state = {
		substitute_id: 0,
		substitute_position: 0,
		substitute_player_positions: [],
		substitute_position_index: -1,
		substitute_is_utility: false,
		swap_trade_out_id: 0,
		substitute_is_bench: false,
		removed_player_params: {},
		show_remove_player_modal: false
	};

	componentDidMount(){
		const { selected_trade_object, addPlayerOutTrade } = this.props;
		const player_id = selected_trade_object.id;

		if (!_.isEmpty(selected_trade_object) && this.checkIsPlayerChangeAvailable(player_id)) {
			addPlayerOutTrade({ ...selected_trade_object });
		}
	}

	get team_info() {
		const { team = {}, positions, bye_detector_controls, controls } = this.props;
		return <TeamInfoWrapper>
			<TeamInfoBlock lineup={team.lineup} positions={positions} short_name extra_small />
			{ controls }
			{ bye_detector_controls }
		</TeamInfoWrapper>;
	}
	get list_view_field(){
		const { positions, data_loading_complete } = this.props;
		if (!data_loading_complete) {
			return (
				<div>
					Loading, please wait...
				</div>
			);
		}

		return <TeamList>
			{ positions.map(this.getListFieldRow) }
			{ this.utility_position }
		</TeamList>;

	}

	get game_bar_stats_value() {
		return _.get(this.props.score_stat, "key");
	}

	get utility_position() {
		const { team: { lineup = {} } } = this.props;
		const { bench = {}, captain, vice_captain  } = lineup;
		const { emergency = [] } = bench;
		const utility = _.get(bench, "utility");
		const utility_id = _.get(utility, "id", 0);
		const full_name = "Utility";
		const name = "Utility";
		const id = _.get(bench, "utility.position", 2);
		const position_index = id === 3 ? 1 : 2;

		return (
			<ListRow key={id}>
				<ListRowLabel>
					{full_name}
				</ListRowLabel>
				<ListBenchPlayers>
					{this.getListViewPlayersByPosition(
						{
							player_id: utility_id,
							id,
							name,
							is_bench: true,
							captain,
							vice_captain,
							emergency,
							is_utility: true
						},
						position_index
					)}
				</ListBenchPlayers>
			</ListRow>
		);
	}

	getListFieldRow({ id, full_name, name }) {
		const { team: { lineup = {} } } = this.props;
		const { bench = {}, captain, vice_captain  } = lineup;
		const { emergency = [] } = bench;

		const position_line = _.get(lineup, id, []);
		const position_bench = _.get(bench, id, []);

		return (
			<ListRow key={id}>
				<ListRowLabel>
					{full_name}
				</ListRowLabel>
				{_.map(position_line, (player_id, index) => this.getListViewPlayersByPosition(
					{
						player_id,
						id,
						name,
						is_bench: false,
						captain,
						vice_captain,
						emergency,
						is_utility: false
					},
					index
				))}
				<ListBenchPlayers>
					{_.map(position_bench, (player_id, index) => this.getListViewPlayersByPosition(
						{
							player_id,
							id,
							name,
							is_bench: true,
							captain,
							vice_captain,
							emergency,
							is_utility: false
						},
						index
					))}
				</ListBenchPlayers>
			</ListRow>
		);
	}
	findTradeByPlayerId(id) {
		const { players_on_trade } = this.props;
		return players_on_trade.find(trade => {
			if (trade.id) {
				return false;
			}
			const tradeIDs = _.get(trade, 'swap_ids', []);
			return (!_.isEmpty(trade.out) && trade.out === id) || tradeIDs.includes(id);
		});
	}

	getPrevisionId({ is_utility, initial_lineup, line, index }) {
		return is_utility ?
			_.get(initial_lineup, "bench.utility.id") :
			_.get(line, index);
	}

	getLine({ initial_lineup, is_bench, is_utility, id }) {
		if (is_utility) {
			return [_.get(initial_lineup, "bench.utility.id", [])];
		}
		else {
			return _.get(initial_lineup, is_bench ? ["bench", id] : id, []);
		}
	}

	getListViewPlayersByPosition(params, index) {
		
		const { substitute_id, substitute_position, substitute_is_utility } = this.state;
		const {rounds} = this.props;
		const passedProps = {
			...this.props,
			rounds,
			params,
			substitute_id,
			substitute_position,
			substitute_is_utility,
			index,
			cancelSubstitute: this.cancelSubstitute,
			checkIsPlayerChangeAvailable: this.checkIsPlayerChangeAvailable,
			findTradeByPlayerId: this.findTradeByPlayerId,
			getLine: this.getLine,
			getPrevisionId: this.getPrevisionId,
			inSubstitute: this.inSubstitute,
			is_empty_cell: this.is_empty_cell,
			removePlayerOnTrade: this.removePlayerOnTrade,
			setPlayerOnTrade: this.setPlayerOnTrade,
			showTradeInPlayer: this.showTradeInPlayer,
			substitutePlayer: this.substitutePlayer,
			trade_id: this.props.trade_id
			
		};
		return <ListViewPlayersByPosition 
			{...passedProps}
			key={`${index}-player-by-position`}
		/>;
	}

	showTradeInPlayer(params) {
		const { substitute_id, substitute_position } = this.state;
		const passedProps = {
			...this.props,
			substitute_id,
			substitute_position,
			params,
			cancelSubstitute: this.cancelSubstitute,
			checkIsPlayerChangeAvailable: this.checkIsPlayerChangeAvailable,
			inSubstitute: this.inSubstitute,
			is_empty_cell: this.is_empty_cell,
			removePlayerOnTrade: this.removePlayerOnTrade,
			substitutePlayer: this.substitutePlayer,
		};

		return <ShowTradeInPlayer 
			{...passedProps}
		/>;
	}

	get is_edit_page() {
		return isEditTradePage();
	}

	setPlayerOnTrade({ currentTarget }) {
		const { id, position, is_bench, position_index } = currentTarget.dataset;
		const { addPlayerOutTrade, trade_id, user_trades, initial_players_on_load } = this.props;
		const currentTrade = user_trades.find(trade => trade.id === trade_id);
		if(currentTrade && currentTrade.out !== 0 && isEditTradePage()){
			return;
		}
		if(initial_players_on_load.includes(Number(id))){
			return;
		}
		// check if empty spot to trade a player into for that trade
		
		addPlayerOutTrade({
			id: parseInt(id, 10),
			position: parseInt(position, 10),
			is_bench: JSON.parse(is_bench),
			position_index: parseInt(position_index, 10)
		});
	}

	removePlayerOnTrade({ currentTarget }) {
		const { position, position_index, is_bench } = currentTarget.dataset;
		const { removePlayerOutTrade } = this.props;
		let previousID = currentTarget.dataset.previous_id;
		
		const trade = this.findTradeByPlayerId(parseInt(previousID, 10));
		if(!previousID && isEditTradePage()){
			previousID = this.props.player_last_removed;
			const params = {
				id: parseInt(previousID, 10),
				position: parseInt(position, 10),
				position_index: parseInt(position_index, 10),
				is_bench: JSON.parse(is_bench),
			};
			this.props.addPlayerInTradeAndUpdateTeam(params);
			return;
		}
		const removed_player_params = {
			id: parseInt(previousID, 10),
			position: parseInt(position, 10),
			position_index: parseInt(position_index, 10),
			is_bench: JSON.parse(is_bench),
		};
		if (trade && trade.swap_ids) {
			this.setState({ removed_player_params, show_remove_player_modal: true });
		}
		else {
			removePlayerOutTrade(removed_player_params);
			this.cancelSubstitute();
		}
	}

	removePlayerInTrade({ currentTarget }) {
		const { id } = currentTarget.dataset;
		const { removePlayerInTrade } = this.props;
		removePlayerInTrade({
			id: parseInt(id, 10)
		});
	}

	undoTrade() {
		const { removed_player_params } = this.state;
		const { removePlayerOutTrade } = this.props;

		removePlayerOutTrade(removed_player_params);
		this.cancelSubstitute();
		this.handleConfirmRemovePlayerModal();
	}

	handleConfirmRemovePlayerModal() {
		this.setState({ removed_player_params: {}, show_remove_player_modal: false });
	}

	checkIsPlayerChangeAvailable(player_id) {
		const { actual_round, is_team_started, players_by_id } = this.props;
		const player = players_by_id[player_id];
		const { status, lockout } = actual_round;
		const is_active = status === "active";

		if (!is_team_started) {
			return true;
		}

		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else {
				/* Player should be locked, if their data hasn't loaded yet */
				return !_.get(player, "locked", true);
			}
		}

		return true;
	}


	substitutePlayer({ currentTarget }) {
		const {
			id,
			position,
			position_index,
			is_bench,
			is_trade_out,
			is_utility
		} = currentTarget.dataset;
		const { players_by_id, player_last_removed } = this.props;
		const idHandled = id ? id : player_last_removed;
		const int_id = parseInt(idHandled, 10);
		const int_position = parseInt(position, 10);
		const int_position_index = parseInt(position_index, 10);
		const bool_is_bench = JSON.parse(is_bench);
		const bool_is_trade_out = JSON.parse(is_trade_out);
		const boolean_is_utility = JSON.parse(is_utility);
		const player = players_by_id[int_id];
		const player_positions = _.get(player, "positions", []) || [position];

		const {
			substitute_id,
			substitute_position,
			substitute_position_index,
			substitute_is_bench,
			substitute_is_utility
		} = this.state;

		const subId = substitute_id || 0;


		if (isAllTrue([
			subId === int_id,
			!boolean_is_utility
		])) {
			return this.cancelSubstitute();
		}

		if (subId) {
			return this.tryToSubstitute({
				player_in: {
					id: int_id,
					position: int_position,
					position_index: int_position_index,
					is_bench: bool_is_bench,
					is_utility: boolean_is_utility
				},
				player_out: {
					id: subId,
					position: substitute_position,
					position_index: substitute_position_index,
					is_bench: substitute_is_bench,
					is_utility: substitute_is_utility
				}
			});
		}

		if (bool_is_trade_out) {
			this.setState({
				swap_trade_out_id: int_id
			});
		}


		this.setState({
			substitute_id: int_id,
			substitute_position: int_position,
			substitute_player_positions: player_positions,
			substitute_position_index: int_position_index,
			substitute_is_bench: bool_is_bench,
			substitute_is_utility: boolean_is_utility,
		});
	}
	findLeader(id) {
		const { team: { lineup: { captain, vice_captain } } } = this.props;
		return _.findKey({ captain, vice_captain }, player_id => player_id === id);
	}
	tryToSubstitute({ player_in, player_out }) {
		const { team, swapPlayersOutTrade, initial_lineup } = this.props;
		const { lineup } = team;
		const { bench } = lineup;
		const { emergency } = bench;
		const what_leader_out = this.findLeader(player_out.id);
		const what_leader_in = this.findLeader(player_in.id);
		const utility = _.get(initial_lineup, "bench.utility");

		let new_lineup = {
			...lineup,
			...this.swapPlayersOnField({ player_in, player_out, utility })
		};

		new_lineup = {
			...new_lineup,
			bench: {
				...new_lineup.bench,
				emergency: this.swapEmergency(emergency, player_out.id, player_in.id),
			}
		};

		if (what_leader_out && player_in.is_bench) {
			new_lineup = {
				...new_lineup,
				[what_leader_out]: player_in.id
			};
		}
		if (what_leader_in && player_out.is_bench) {
			new_lineup = {
				...new_lineup,
				[what_leader_in]: player_out.id
			};
		}
		swapPlayersOutTrade({
			swap_in_id: player_in.id,
			swap_trade_id: player_out.id,
			swap_position: player_in.position,
			lineup: new_lineup
		});

		this.cancelSubstitute();
	}

	swapPlayersOnField({ player_in, player_out, utility }) {
		const { team } = this.props;
		const { lineup: current_lineup } = team;
		let utility_lineup = {
			...current_lineup
		};

		const { swap_trade_out_id } = this.state;

		let new_lineup = {
			...utility_lineup
		};

		if (player_in.is_bench) {
			new_lineup = swapPlayersOnBench({
				new_lineup,
				bench_player: player_in,
				other_player: player_out,
				swap_trade_out_id
			});
		}
		else {
			const line = setPlayerId(
				new_lineup[player_in.position], player_in.position_index, player_out.id,
				swap_trade_out_id
			);
			new_lineup = {
				...new_lineup,
				[player_in.position]: line
			};
		}

		if (player_out.is_bench) {
			new_lineup = swapPlayersOnBench({
				new_lineup,
				bench_player: player_out,
				other_player: player_in,
				swap_trade_out_id
			});
		}
		else {
			const line = setPlayerId(
				new_lineup[player_out.position], player_out.position_index, player_in.id,
				swap_trade_out_id
			);
			new_lineup = {
				...new_lineup,
				[player_out.position]: line
			};
		}
		const {formation} = convertLineupForBE(new_lineup); 
		if(isAllTrue([!isNaN(player_in.id), isEditTradePage()]) ){
			this.props.updatePlayerSwap({
				trade_id: this.props.trade_id,
				swap_id: player_in.id,
				swap_formation: formation
			});
		}
		return {
			...new_lineup
		};

	}

	swapEmergency(emergency, player_out_id, player_in_id) {
		const out_index = emergency.indexOf(player_out_id);
		const in_index = emergency.indexOf(player_in_id);
		if (~out_index) {
			emergency[out_index] = player_in_id;
		}
		if (~in_index) {
			emergency[in_index] = player_out_id;
		}

		return emergency;
	}

	cancelSubstitute() {
		const { substitute_id } = this.state;
		if (substitute_id) {
			this.setState({
				substitute_id: 0,
				substitute_position: 0,
				substitute_player_positions: [],
				swap_trade_out_id: 0,
				substitute_is_utility: false
			});
		}
	}

	inSubstitute(is_subs, is_locked, in_position, out_position) {
		return is_subs && !is_locked && in_position && out_position;
	}

	get is_empty_cell() {
		const { team } = this.props;
		const not_traded_players = _.map(this.props.players_on_trade, trade => trade.out || 0);
		const empty_cells = not_traded_players.filter(value => value === 0);

		const players_on_trade = not_traded_players.filter(value => value !== 0);

		return empty_cells.length && team.week_trades_left !== 0 && players_on_trade.length < 2;
	}

	get heading() {
		const { onSwitchPlayerPool } = this.props;

		return (
			<SectionHeader>
				<span>Your team</span>
				<OpenTeamFieldButton onClick={onSwitchPlayerPool} inverted>
					<span>Player pool</span>
					<Arrow direction="right"/>
				</OpenTeamFieldButton>
			</SectionHeader>
		);
	}

	get remove_player_modal() {
		const { is_mobile } = this.props;
		return (
			<ModalContainer
				onClick={this.handleConfirmRemovePlayerModal}
				container_background="#F8F8FA"
			>
				<Caution size={3} color={colors.warning} />
				<ModalInnerTitle>
					Are you sure you want to reverse this trade?
				</ModalInnerTitle>
				<ModalInnerSubTitle>
					As this was an advanced trade this action will impact all players involved,
					including those you swapped during the trade.
				</ModalInnerSubTitle>

				<ConfirmButtonsWrapper>
					<ButtonSecondary inverted onClick={this.handleConfirmRemovePlayerModal}>
						{is_mobile ? "CANCEL" : "No, keep these players"}
					</ButtonSecondary>
					<ButtonAlert
						onClick={this.undoTrade}
					>
						{is_mobile ? "CONFIRM" : "Yes, undo this trade"}
					</ButtonAlert>
				</ConfirmButtonsWrapper>

			</ModalContainer>
		);
	}

	render() {
		const {
			selected_round_id,
			is_mobile,
			players_by_id,
			remaining_salary,
			team,
			is_team_started,
			has_assistant_coach,
			data_loading_complete,
			mobile_stat,
			score_stat,
			rounds_with_matches,
		} = this.props;

		const { show_remove_player_modal } = this.state;

		if (_.isEmpty(players_by_id)) {
			return null;
		}

		return (
			<div>
				{this.heading}
				<UserTeamInfo is_mobile={is_mobile}>
					<TeamGameBar
						player_points={this.game_bar_stats_value}
						is_type_field_list
						selected_round_id={selected_round_id}
						is_mobile={is_mobile}
						players_by_id={players_by_id}
						remaining_salary={remaining_salary}
						team={team}
						is_team_started={is_team_started}
						has_assistant_coach={has_assistant_coach}
						data_loading_complete={data_loading_complete}
						label_less_padding
						is_current_round
						rounds_with_matches={rounds_with_matches}
					/>

					{ this.team_info }
				</UserTeamInfo>
				<ListViewHeader
					player_ids={[]}
					is_trades
					score_stat={score_stat}
					mobile_stat={mobile_stat}
				/>
				{ this.list_view_field }

				{show_remove_player_modal ? this.remove_player_modal : null}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const actual_round = selectors.rounds.getActualRound(state);
	const last_round = selectors.rounds.getLastCompleteRound(state) || {};
	const { lifted_at = 0, id: last_round_id } = last_round;
	const is_old_scores = new Date().getTime() - new Date(lifted_at).getTime() < ONE_DAY_MS;

	let round_id = _.isEmpty(actual_round) ? 0 : actual_round.id;
	if (is_old_scores && last_round_id) {
		round_id = last_round_id;
	}
	const players_on_trade = _.get(state, 'teamsClassic.trades.players_on_trade', []);
	const players_by_id = selectors.players.getExtendedPlayersById(state);
	const in_players_ids = _.chain(players_on_trade)
		.map(trade => trade.in || 0)
		.filter(_.identity)
		.uniq()
		.value();
		
	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	return {
		players_by_id,
		actual_round,
		team: selectors.getMyClassicTeam(state),
		positions: selectors.positions.getPositionsArray(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		is_pending_players: state.players.is_pending,
		errors: state.teamsClassic.show_my.error,
		last_round: selectors.rounds.getLastCompleteRound(state),
		is_team_started: selectors.isMyTeamStarted(state),
		player_ids: selectors.getMyClassicTeamPlayersIds(state),
		selected_round_id: round_id,
		remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
		initial_players_on_load: state.teamsClassic.trades.initial_trades_on_load,
		rounds: selectors.rounds.getRounds(state),
		data_loading_complete: !_.includes([
			state.players.is_pending,
			state.rounds.is_pending,
			state.squads.is_pending,
			state.venues.is_pending,
			state.teamsClassic.show_my.is_pending,
			(Boolean(selectors.getUser(state).assistant_coach))?
				_.isEmpty(state.players.coach_by_id): false
		], true),
		user_trades: selectors.getUserTrades(state),
		initial_lineup: _.get(
			selectors.setUtilityPosition(state.teamsClassic.trades, in_players_ids),
			"lineup",
			[]
		),
		players_on_trade: selectors.getUserTrades(state),
		selected_trade_object: selectors.getSelectedTradeObject(state, props),
		rounds_with_matches
	};
};
const mapDispatchToProps = {
	addPlayerOutTrade: actions.addPlayerOutTrade,
	addPlayerInTrade: actions.addPlayerInTrade,
	removePlayerOutTrade: actions.removePlayerOutTrade,
	removePlayerInTrade: actions.removePlayerInTrade,
	swapPlayersOutTrade: actions.swapPlayersOutTrade,
	updateCompletedTrade: actions.updateCompletedTrade,
	removePlayer: actions.removePlayerFromMyClassicTeam,
	addPlayer: actions.addPlayerToMyClassicTeam,
	updatePlayerSwap: actions.updatePlayerSwap,
	addPlayerInTradeAndUpdateTeam: actions.addPlayerInTradeAndUpdateTeam
};
export const UserTeam = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserTeamComponent));

export default UserTeam;